import {BasicAuthSession, BasicAuthUserInfo} from '../basic-auth-session';
import React, {Reducer, useEffect, useReducer} from 'react';
import {SessionManager, SessionType} from '../../session-manager';
import {Button} from '../../../../components/form/button/button';
import {useHistory} from 'react-router-dom';
import './basic-auth-login-page.scss';
import i18n from '../../../i18n';
import {env_vars} from '../../../load-env';
import {Breadcrumbs} from '../../../../components/breadcrumbs/breadcrumbs';

interface FormDataState {
  userInfo: BasicAuthUserInfo;
  error: string;
}

const initFormDataState: FormDataState = {
  userInfo: {
    hunterid: '',
    birthdate: ''
  },
  error: ''
};

type LoginReducer = Reducer<FormDataState, { type: 'change' | 'error'; payload: any }>;

export const BasicAuthLoginPage: React.FC = () => {
  const history = useHistory();




  const [formData, updateFormData]: [FormDataState, Function] = useReducer<LoginReducer>((state, action) => {
    switch (action.type) {
      case 'change':
        return {
          ...state,
          userInfo: {
            ...state.userInfo,
            [action.payload.name]: action.payload.value
          }
        };
      case 'error':
        return {
          ...state,
          error: action.payload
        };
    }
  }, initFormDataState);


  useEffect(() => {
    SessionManager.createSession(SessionType.BASIC_AUTH).then(session => {
      const userInfo = (session as BasicAuthSession).getUserInfo();
      if (userInfo) {
        session.signin(userInfo).then(() => {
          history.push('/utenlandsk/' + i18n.language);
        });
      }
    });
  }, [history]);

  const validateForm = (): string => {
    if (!formData.userInfo.hunterid.match(/^((\d{6})|(\d{8}))$/)) {
      return i18n.t('basicAuthLogin:jegernrSkalBestataAv6Eller8Siffer');
    } else if (!formData.userInfo.birthdate.match(/^\d{6}$/)) {
      return i18n.t('basicAuthLogin:fodselsdatoSkalBestaaAv6Siffer');
    } else {
      return '';
    }
  };


  const onLogin = (event: any) => {
    event.preventDefault();

    const errMsg = validateForm();
    updateFormData({type: 'error', payload: errMsg});
    if (!errMsg.length) {
      SessionManager.session.signin(formData.userInfo).then(() => {
        history.push('/utenlandsk/' + i18n.language);
      }).catch(e => {
        updateFormData({type: 'error', payload: e.message});
      });
    }
  };

  return (
    <div className='container'>
      <Breadcrumbs crumbKey="jegerside" data-tip={i18n.t('felles:minJegerside')}/>
      <section className='mt-3'>
        <>
        <h1>{i18n.t('felles:minJegersideUtenlandsk') as string}</h1>
        <article>
          <p className="intro">
            {i18n.t('basicAuthLogin:foraaloggeinn') as string}
          </p>
        </article>
        <form id='login-form' onSubmit={onLogin}>
          <label htmlFor='hunterid'>
            <>
            {i18n.t('felles:jegernummer')}
            {' '}
            <em className='myDiv'>{i18n.t('basicAuthLogin:jegernummerbestaar') as string}</em>
              </>
          </label>
          <input name='hunterid'
                 className='login-input'
                 placeholder={i18n.t('basicAuthLogin:jegernummer') + '...'}
                 onChange={(e) => updateFormData({type: 'change', payload: e.target})}
                 value={formData.userInfo.hunterid}
                 inputMode='numeric'
                 type='text'
                 autoComplete='off'
                 maxLength={8}/>
          <label htmlFor='birthdate'>
            <>
            {i18n.t('basicAuthLogin:fodselsdato')}
            {' '}
            <em className='myDiv'>{i18n.t('basicAuthLogin:tastinnfodselsdato') as string}</em>
              </>
          </label>
          <input name='birthdate'
                 className='login-input'
                 placeholder={i18n.t('basicAuthLogin:ddmmyy')}
                 onChange={(e) => updateFormData({type: 'change', payload: e.target})}
                 value={formData.userInfo.birthdate}
                 inputMode='numeric'
                 type='text'
                 autoComplete='off'
                 maxLength={6}/>
          <br/>
          {formData.error
                ? <div id='login-error' className='alert-danger login-error'>{formData.error}</div>
                : null}
          <br/>
          <Button id='login-btn'
                  aria-label="Right Align"
                  isSubmit isPrimary>
            {i18n.t('basicAuthLogin:login')}
          </Button>
        </form>
        <h2>
          {i18n.t('basicAuthLogin:herKanDu') as string}
        </h2>
        <ul>
          <li>{i18n.t('basicAuthLogin:betaleJegeravgiften') as string}</li>
          <li>{i18n.t('basicAuthLogin:lasteNedJegeravgiftskortet') as string}</li>
          <li>{i18n.t('basicAuthLogin:finneUtOmJegeravgiftenErBetalt') as string}</li>
          <li>{i18n.t('basicAuthLogin:registrereDegSomLisensjeger') as string}</li>
        </ul>
        <a className="link" href={env_vars.BRREG_FORSIDE_URL + 'hvordan-kan-vi-hjelpe-deg/'}>
          {i18n.t('basicAuthLogin:taKontakt') as string}
        </a>
        {i18n.t('basicAuthLogin:medJegerregisteretHvisDuHarsporsmaal')}
          </>
      </section>
    </div>
  );
};
