import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {SessionManager, SessionType} from '../../../utilities/session-management/session-manager';

function Hundeopplysninger(props) {
  const [data, setData] = useState([]);
  const [isLoading,setIsLoading] = useState(true);


  useEffect( () =>{
    if (SessionManager.sessionType !== SessionType.BASIC_AUTH) {
      const getEttersokshund = async() =>{
        await JegerwebApiService.get('/jeger/dogs/')
          .then(response => {
            setData(response.data);
          }).catch(err => {
            console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
          }).finally(() => {
            setIsLoading(false);
          });
      };
      if (props.jegernr !== '') {
        getEttersokshund();
      }
    }
  },[props.jegernr]);


  const DisplayTableHeader = () => (
    <thead>
      <tr>
        <th className="table-cell-headline">{i18n.t('hunder:regnr')}</th>
        <th className="table-cell-headline">{i18n.t('hunder:id')}</th>
        <th className="table-cell-headline">{i18n.t('hunder:navnHund')}</th>
        <th className="table-cell-headline">{i18n.t('hunder:rase')}</th>
        <th className="table-cell-headline">{i18n.t('hunder:født')}</th>
      </tr>
    </thead>
  );

  const DisplayTableData = () => data && (
    data.map((dog) => (
      <tr className="data" key={dog.regnr}>
        <td data-heading={i18n.t('hunder:regnr')} className="table-cell-data">{dog.regnr}</td>
        <td data-heading={i18n.t('hunder:id')} className="table-cell-data">{dog.chipid}</td>
        <td data-heading={i18n.t('hunder:navnHund')} className="table-cell-data">{dog.navn}</td>
        <td data-heading={i18n.t('hunder:rase')} className="table-cell-data">{dog.rase}</td>
        <td data-heading={i18n.t('hunder:født')} className="table-cell-data">
          {moment(dog.fodt).format('DD.MM.YYYY')}
        </td>
      </tr>
    ))
  );

  if (isLoading || data.length === 0 ) {
    return <></>;
  }

  return (
    <section title="hundeopplysninger">
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <div className="infobox" title="hundeinfo">
            <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
            <span className="helptext normal-br-link">
              {i18n.t('hunder:infoblokk1')}
              {i18n.t('hunder:infoblokk2')}
              {i18n.t('hunder:infoblokk3')}
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container">
          <header>
            <div className="row">
              <div className="col-12 pt-1">
                <div className="border-bottom border-dark">
                  <h2>{i18n.t('hunder:title')}</h2>
                </div>
              </div>
            </div>
          </header>
          <table className="table-container__table table-container__table--break-sm">
            <DisplayTableHeader/>
            <tbody>
              <DisplayTableData/>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Hundeopplysninger;
