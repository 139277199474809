import React, {Component} from 'react';
import {Button} from '../../form/button/button';
import i18n from '../../../utilities/i18n';
import './samtykke.scss';

import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';


class SamtykkeToggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      vaapeninfo: false,
      clicked: false,
      samtykkeRegistreringErrorMessage: '',
    };
    this.handleClick = this.handleClick.bind(this);
  }

  onSubmit = (event) => {
    event.preventDefault();

    JegerwebApiService.post('/vaapen/samtykke', this.state.vaapeninfo)
      .then(response => {
        this.setState({
          vaapeninfo: response.data.vaapeninfo,
          samtykkeRegistreringErrorMessage: ''
        });
        this.props.handleSamtykke(response.data.vaapeninfo);
      }).catch(err => {
        this.setState({samtykkeRegistreringErrorMessage: i18n.t('minJegerside:samtykkeRegistreringErrorMessage')});
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });

  };

  handleClick() {
    this.setState({
      clicked: true
    });
  }

  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  onChangeJa = () => {
    this.setState(initialState => ({
      vaapeninfo: !initialState.vaapeninfo,
    }));
  };

  render() {
    return (
      <>
        <br/>
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <div className="infobox" title="samtykke">
              <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
              <span className="helptext state">
                {i18n.t('minJegerside:forDuKanRegistrere')}
              </span>
            </div>
          </div>
        </div>
        <div title="button - samtykke">
          <Button id="registrer-samtykke-btn"
                  text={i18n.t('minJegerside:samtykkeTilRegistrering')}
                  isPrimary isSubmit
                  onClick={() => this.toggle()}
          >
          </Button>
        </div>
        <div className={'collapse' + (this.state.open ? 'in' : '')} title="samtykke">
          <hr />
          <p>
            {i18n.t('minJegerside:registreringAvVaapen')}
          </p>
          <ul>
            <li>{i18n.t('minJegerside:vaapenDuRegistrerer')}</li>
            <li>{i18n.t('minJegerside:gjennomforteObligatoriske')}</li>
            <li>{i18n.t('minJegerside:omDuHarBestaatt')}</li>
          </ul>
          <p>
            {i18n.t('minJegerside:duFinnerOpplysninger')}
            {' '}
            <span>
              <a href="https://www.brreg.no/personvernerklaering/">
                {i18n.t('minJegerside:personvernerklaering1')}
              </a>
            </span>
            .
          </p>
          {this.state.samtykkeRegistreringErrorMessage ?
            this.state.samtykkeRegistreringErrorMessage && <h1 className="nettverksfeil">
              {this.state.samtykkeRegistreringErrorMessage}
            </h1> : null}
          <label className="radio radio-gradient">
            <span className="radio__input">
              <input type="radio"
                     checked={this.state.vaapeninfo}
                     onChange={this.onChangeJa}
                     name="radio"
                     />
              <span className="radio__control"/>
            </span>
            <span className="radio__label">
              {i18n.t('minJegerside:jaJegForstaar')}
            </span>
          </label>
          <br/>
          <Button id="registrer-samtykke-btn1"
                  text={i18n.t('minJegerside:lagreSamtykke')}
                  aria-label="Left Align"
                  isPrimary isSubmit
                  disabled={!this.state.vaapeninfo}
                  onClick={this.onSubmit}
                  />
          <Button id="registrer-samtykke-btn3"
                  text={i18n.t('minJegerside:avbryt')}
                  aria-label="Left Align"
                  isPrimary
                  onClick={() => this.toggle()}
                  />
        </div>
      </>
    );
  }
}

export default SamtykkeToggle;

