import React, {Component} from 'react';
import './jegervalg.scss';
import {JEGER_BETALINGSVALG} from '../../../consts/betalingsvalg';
import {Radiovalg} from './radiovalg';
import {Link} from 'react-router-dom';
import i18n from '../../../utilities/i18n';

export class Jegervalg extends Component {

  render() {
    return (
      <section>
        <div>
          {(this.props.person.vilttype === undefined || this.props.person.vilttype !== 'Storvilt') && (
          <h2>{i18n.t('betaling:vennligstVelgHvilkenTjenesteDuOnskerAaBetaleFor')}</h2>
                    )}
          {(this.props.person.vilttype === 'Storvilt') && (
          <h2>{i18n.t('betaling:duHarAlleredeBetaltJegeravgiften')}</h2>
                    )}
        </div>
        <div className='section-body'>
          {this.props.person.vilttype === undefined && (
          <>
            <Radiovalg radioId='radioStorvilt'
                       radioValue={JEGER_BETALINGSVALG.storvilt}
                       label={i18n.t('betaling:jaktPaaAltVilt') + ' (' + this.props.person.jaktaar + ')'}
                       pris={this.props.priser.storviltPris}
                       valg={this.props.valg}
                       updateValg={this.props.updateValg}
                            />
            <Radiovalg radioId='radioSmaavilt'
                       radioValue={JEGER_BETALINGSVALG.smaavilt}
                       label={i18n.t('betaling:jaktPaaAltViltUnntatt') +
                                                    ' (' + this.props.person.jaktaar + ')'}
                       pris={this.props.priser.smaaviltPris}
                       valg={this.props.valg}
                       updateValg={this.props.updateValg}
                            />
          </>
                    )}
          {this.props.person.vilttype === 'Småvilt' && (
          <Radiovalg radioId='radioUtvidelse'
                     radioValue={JEGER_BETALINGSVALG.utvidelse}
                     label={i18n.t('betaling:utvidTilJaktPaaAltVilt') + ' (' +
                                            this.props.person.jaktaar + ')'}
                     pris={this.props.priser.utvidelsePris}
                     valg={this.props.valg}
                     updateValg={this.props.updateValg}
                        />
                    )}
          {this.props.person.vilttype === 'Storvilt' && (
          <section>
            <div className='section-header normal-br-link'>
              {i18n.t('betaling:jegeravgiftskortetFinnerDuPaa') }
              <Link className="link" to='/'>{i18n.t('felles:minJegerside')}</Link>
              .
            </div>
          </section>
                    )}
        </div>
      </section>
    );
  }
};
