import React, {Component} from 'react';
import i18n from '../../../utilities/i18n';
import {AuthGuard} from '../../../utilities/session-management/auth-guard';
import {SessionType} from '../../../utilities/session-management/session-manager';


class Punktliste extends Component {
  render() {
    return (
      <>
        <h1>{i18n.t('felles:minJegerside')}</h1>
        <p className="intro">
          {i18n.t('minJegerside:herPaaMinJegerside')}
          {i18n.t('minJegerside:betaleJegeravgiften')}
          {i18n.t('minJegerside:finneOgLaste')}
          {i18n.t('minJegerside:registrereOgSkrive')}
          <AuthGuard sessionType={SessionType.OIDC}>
            {i18n.t('minJegerside:registrereVaapen')}
            {i18n.t('minJegerside:seObligatoriskeSkudd')}
            {i18n.t('minJegerside:finneInstruktoerbevis')}
          </AuthGuard>
        </p>
      </>
    );
  }
}
export default Punktliste;

