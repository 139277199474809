import axios from 'axios';
import {SessionManager, SessionType} from '../../utilities/session-management/session-manager';
import {env_vars} from '../../utilities/load-env';
import i18n from '../../utilities/i18n';

export class JegerwebApiService {
  static async get(path, config) {
    return axios.get(this.getUrl(path), await this.getAxoisConfig(config));
  }

  static async put(path, data, config) {
    return axios.put(this.getUrl(path), data, await this.getAxoisConfig(config));
  }

  static async post(path, data, config) {
    return axios.post(this.getUrl(path), data, await this.getAxoisConfig(config));
  }

  static async delete(path, config) {
    return axios.delete(this.getUrl(path), await this.getAxoisConfig(config));
  }


  static async getAxoisConfig(config) {
    const headers = {
      'Content-Type': 'application/json'
    };
    switch (SessionManager.sessionType) {
      case SessionType.OIDC:
        return {
          ...config,
          headers: {
            ...headers,
            ...await SessionManager.session.getAuthHeader()
          }
        };
      case SessionType.BASIC_AUTH:
        if ((config && config.auth) || !SessionManager.session.userInfo) {
          return {
            headers,
            ...config
          };
        } else {
          return {
            ...config,
            headers,
            auth: {
              username: SessionManager.session.userInfo.hunterid,
              password: SessionManager.session.userInfo.birthdate
            }
          };
        }
      default:
        console.error('Session type not set');
        return {
          headers,
          ...config
        };
    }
  }

  static getUrl(path) {
    const wildcardMapper = (wcName) => {
      switch (wcName) {
        case ':sessiontype':
          return (SessionManager.sessionType === SessionType.BASIC_AUTH) ? 'utenlandsk' : null;
        case ':lang':
          return i18n.language;
        default:
          console.error(`URL-path wildcard '${wcName}' not implemented`);
          return null;
      }
    };

    return env_vars.JEGERWEB_API_URL + path.split('/')
      .map(p => p[0] === ':' ? wildcardMapper(p) : p)
      .filter(p => p != null)
      .join('/');
  }
}
