import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Root} from './app/components/root';
import './index.scss';
import './css/typography.scss';
import './css/colors.scss';
import './css/responsive.scss';
import './css/shadows.scss';
import {UserHeader} from './app/components/user-header/user-header';
import loadEnv from './app/utilities/load-env';
import {initI18N} from './app/utilities/i18n';

loadEnv().then(async () => {
  await initI18N();
  ReactDOM.render(<Root/>, document.getElementById('root'));
  ReactDOM.render(<UserHeader/>, document.getElementById('user-header-root'));
});
