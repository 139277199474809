import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {formatDato} from '../../../utilities/formatdato';
import i18n from '../../../utilities/i18n';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const TableHeader = (props) => (
  <thead>
    <tr>
      <th className="state text-left"><strong>{i18n.t('minJegerside:vaapennavn')}</strong></th>
      <th className="state"><strong>{i18n.t('minJegerside:kaliber')}</strong></th>
      <th className="state"><strong>{i18n.t('minJegerside:nummer')}</strong></th>
      <th className="state"><strong>{i18n.t('minJegerside:gyldigJaktaar')}</strong></th>

      {props.hideDeleteColumns ? null :
      <th className="state"><strong>{i18n.t('minJegerside:godkjentdato')}</strong></th>}

      {props.hideDeleteColumns ? null :
      <th className="state"><strong>{i18n.t('minJegerside:slettVapaan')}</strong></th>}
    </tr>
  </thead>
);

const TableBody = props => {
  const showToast = () => {
    toast.success(i18n.t('minJegerside:varselSletting'), {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const rows = props.vaapenData.map((row, index) => (
    <tr key={index}>
      <td data-heading={i18n.t('minJegerside:vaapennavn')} className="state text-left">{row.navn}</td>
      <td data-heading={i18n.t('minJegerside:kaliber')} className="state">{row.kaliber}</td>
      <td data-heading={i18n.t('minJegerside:nummer')} className="state">{row.nummer}</td>
      <td data-heading={i18n.t('minJegerside:gyldigJaktaar')}
          className={`state ${row.gyldigJaktaar ? 'positive' : null}`}>
        <strong>{(row.gyldigJaktaar)}</strong>
      </td>
      {props.hideDeleteColumns ? null :
      <td data-heading={i18n.t('minJegerside:godkjentdato')}
          className={`state ${row.godkjentDato ? 'positive' : null}`}>
        <strong>{formatDato(row.godkjentDato)}</strong>
      </td>}
      {props.hideDeleteColumns ? null : row.godkjentDato ?
        <td data-heading="Slett våpen" className="state"/> :
        <td data-heading="Slett våpen" className="state">
          <button className="btn btn-trash"
                  aria-label="Right Align"
                  title="slett våpen"
                  data-dismiss="alert"
                  onClick={() => {
                        props.removeVaapen(index);
                    showToast();
                      }}>
            <FontAwesomeIcon icon={faTrashAlt} size="2x"/>
          </button>
        </td>}
    </tr>
  ));
  return (<tbody>
    {rows}
    <ToastContainer role="alert"/>
  </tbody>
  );
};

const Tablevaapen = (props) => {
  const {vaapenData, removeVaapen, hideDeleteColumns, breakOn = 'small'} = props;
  let tableClass = 'table-container__table';
  if (breakOn === 'small') {
    tableClass += ' table-container__table--break-sm';
  }
  return (
    <section title="våpentabel">
      <div className="container">
        <header>
          <div className="border-bottom border-dark">
            <h2>{i18n.t('minJegerside:registrerteVaapenTilStorviltproven')}</h2>
          </div>
        </header>
        <body>
          <table className={tableClass}>
            <TableHeader hideDeleteColumns={hideDeleteColumns}/>
            <TableBody vaapenData={vaapenData} removeVaapen={removeVaapen} hideDeleteColumns={hideDeleteColumns}/>
          </table>
        </body>
      </div>
    </section>
  );
};

export default Tablevaapen;
