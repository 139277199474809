import * as Oidc from 'oidc-client';
import {ReadyChangeCallback, Session, SessionManager} from '../session-manager';
import {env_vars} from '../../load-env';




export class OidcSession implements Session {
  config = {
    client_id: env_vars.OIDC_CLIENT_ID,
    redirect_uri: env_vars.OIDC_REDIRECT_URI,
    post_logout_redirect_uri: env_vars.OIDC_POST_LOGOUT_REDIRECT_URI,
    scope: 'openid profile',
    response_type: 'code',
    ui_locales: 'nb',
    acr_values: env_vars.OIDC_ACR_VALUES,
    authority: env_vars.OIDC_AUTHORITY,
    automaticSilentRenew: true,
    silent_redirect_uri: env_vars.OIDC_SILENT_REDIRECT_URI,
    error_authenticated_redirect_uri: env_vars.OIDC_ERROR_AUTHENTICATED_REDIRECT_URI
  };

  userManager: Oidc.UserManager;
  user?: Oidc.User | null;
  isLoading = true;

  readyChangeSubs: ReadyChangeCallback[] = [];

  constructor() {
    Oidc.Log.logger = console;
    Oidc.Log.level = Oidc.Log.ERROR;

    this.userManager = new Oidc.UserManager(this.config);
    this.userManager.events.addAccessTokenExpired(() => {
      console.log('Access token expired. Signing out');
      SessionManager.endSession();
    });

    this.userManager.events.addUserSignedOut(async () => {
      console.log('Signed out from another service.');
      await this.userManager.signoutRedirect();
    });


    this.userManager.events.addUserLoaded((user) => {
      this.user = user;
    });
  }

  async start() {
    this.isLoading = true;
    return await this.userManager.getUser().then(async (user) => {
      this.user = user;
      if (!user || user.expired) {
        await this.signin();
        return false;
      } else {
        this.isLoading = false;
        this.readyChange();
        return true;
      }
    }).catch(async (err) => {
      console.error('Failed to load user', err);
      await this.signin();
      return false;
    });
  }

  async signin() {
    await this.userManager.signinRedirect();
  }

  async end() {
    delete SessionManager.sessionType;
    await this.userManager.signoutRedirect();
  }

  async getAuthHeader() {
    if (!this.user) {
      this.user =  await this.userManager.getUser();
    }

    return {
      Authorization: `Bearer ${ this.user ? this.user.access_token : null}`
    };
  }

  isValid() {
    return !!(this.user && !this.user.expired);
  }

  isReady() {
    return this.isValid() && !this.isLoading;
  }

  addReadyChangeListener(readyCallback: (ready: boolean) => void) {
    this.readyChangeSubs.push(readyCallback);
  }

  readyChange() {
    this.readyChangeSubs.forEach(callback => callback(this.isReady()));
  }
}
