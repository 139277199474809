export const formatDato = (yyyyMmDd) => {
  if (yyyyMmDd) {
    const YEAR_INDEX = 0;
    const MONTH_INDEX = 1;
    const DAY_INDEX = 2;

    const dateParts = yyyyMmDd.split('-');
    return dateParts[DAY_INDEX] + '.' + dateParts[MONTH_INDEX] + '.' + dateParts[YEAR_INDEX];
  }
  return '';
};
