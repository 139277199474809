import React, {Component} from 'react';
import {Jegervalg} from './jegervalg';
import {Jegeroppsummering} from './jegeroppsummering';
import {JEGER_BETALINGSVALG} from '../../../consts/betalingsvalg';
import './jegerbetalingsdialog.scss';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';

export class Jegerbetalingsdialog extends Component {

  state = {jegerdata: {}, prisdata: {}, valg: ''};

  componentDidMount() {
    JegerwebApiService.get('/jeger/:sessiontype/:lang')
      .then((jegerdata) => {
        this.setState({jegerdata: jegerdata.data});
        const produkt = jegerdata.data.vilttype === 'Småvilt' ? 'smaavilt' : 'storvilt';
        JegerwebApiService.get(`/jeger/:sessiontype/${produkt}/:lang`)
          .then(prisdata => {
            this.setState({prisdata: prisdata.data});
          }).catch(err => {
            console.error('Feil GET detalj jeger (2): ' + err);
          });
      }).catch(err => {
        console.error('Feil GET detalj jeger (1): ' + err);
      });
  }

  updateValg(valg) {
    let sum = 0;
    switch (valg) {
      case JEGER_BETALINGSVALG.smaavilt:
        sum += this.state.prisdata.smaaviltPris;
        break;
      case JEGER_BETALINGSVALG.storvilt:
        sum += this.state.prisdata.storviltPris;
        break;
      case JEGER_BETALINGSVALG.utvidelse:
        sum += this.state.prisdata.utvidelsePris;
        break;
      default:
        console.error();
    }

    if (this.state.prisdata.tilleggsavgift) {
      sum += this.state.prisdata.tilleggsavgiftPris;
    }

    this.setState({valg, sum});
  }

  render() {
    return (
      <section>
        <Jegervalg person={this.state.jegerdata}
                   priser={this.state.prisdata}
                   valg={this.state.valg}
                   updateValg={(valg) => this.updateValg(valg)}
                />
        <Jegeroppsummering person={this.state.jegerdata}
                           priser={this.state.prisdata}
                           valg={this.state.valg}
                           sum={this.state.sum}
                />
      </section>
    );
  }

}
