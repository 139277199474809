import React, {useEffect} from 'react';
import {SessionManager, SessionType} from './session-manager';
import {Route} from 'react-router';
import {BasicAuthLoginPage} from './basic-auth/basic-auth-login-page/basic-auth-login-page';
import {useSessionType} from '../../hooks/use-session-type';

const PreLogin: React.FC<{ sessionType: SessionType }> = (props) => {
  useEffect(() => {
    SessionManager.createSession(props.sessionType).then(() => {
      SessionManager.session.signin();
    });
  }, [props.sessionType]);

  return <>redirecting...</>;
};


interface LoginRouteProps {
  path: string;
}


export const LoginRoute: React.FC<LoginRouteProps> = (props) => {
  const sessionType = useSessionType();

  return (
    <Route exact path={props.path}>
      {
      sessionType === SessionType.BASIC_AUTH
        ? <BasicAuthLoginPage/>
        : <PreLogin sessionType={sessionType}/>
    }
    </Route>
  );
};

