import React, {Component} from 'react';
import {SessionManager, SessionType} from '../../../../utilities/session-management/session-manager';
import {JegerwebApiService} from '../../../../services/api-service/jegerweb-api-service';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import MedlemskapTable from '../medlemskap_table';
import MedlemskapForm from '../medlemskap-form';
import '../../../form/icons/app-helptext-icon.scss';
import '../../../form/icons/infoboks.scss';
import '../../../../components/minjegerside/vaapentable/tablevaapen_table.scss';
import i18n from '../../../../utilities/i18n';
import {HttpStatus} from '../../../../consts/http-status';


class MedlemskapApp extends Component {

  state = {
    medlemsData: [{
      jegernr: '',
      medlemsnr: '',
      medlemsorganisasjon: '',
      verifisert: '',
    }],
  };
  componentDidMount() {
    if (SessionManager.sessionType === SessionType.OIDC) {
      JegerwebApiService.get('/medlem/')
        .then((response) => {
          const medlemsData = response.data;
          this.setState({
            medlemsData,
          });
        }).catch(error => {
          const statusCode = error.response ? error.response.status : null;
          if (statusCode === HttpStatus.NOT_FOUND) {
            return null;
          }
        });
    }
  }
  removeMedlem(index) {
    JegerwebApiService.delete(`/medlem/${this.state.medlemsData[index].medlemsnr}/${this.state.medlemsData[index].medlemsorganisasjon}`)
      .then((response) => {
        this.setState({
          medlemsData: response.data,
        });
      }).catch(err => {
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
  }

  handleSubmits = medlem => {
    JegerwebApiService.put('/medlem/', medlem)
      .then((res) => {
        this.setState({
          medlemsData: res.data,
        });
      }).catch(err => {
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
  };

  minstEttMedlemskap(medlemsData) {
    for (const medlem of medlemsData) {
      if (medlem.medlemsnr) {
        return true;
      }
    }
    return false;
  }

  render() {
    return (
      <>
        { this.minstEttMedlemskap(this.state.medlemsData) &&
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <br />
              <div className="infobox" title="terminfestet tabell">
                <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
                <span className="helptext">
                  {i18n.t('minJegerside:tabellenViserMedlemskap1')}
                  <ul>
                    <li>
                      {i18n.t('minJegerside:tabellenViserMedlemskap2')}
                      {' '}
                      <strong>{i18n.t('minJegerside:tabellenViserMedlemskap3')}</strong>
                      ,
                      {' '}
                      {i18n.t('minJegerside:tabellenViserMedlemskap4')}
                    </li>
                    <li>
                      {i18n.t('minJegerside:tabellenViserMedlemskap2')}
                      {' '}
                      <strong>{i18n.t('minJegerside:tabellenViserMedlemskap6')}</strong>
                      ,
                      {i18n.t('minJegerside:tabellenViserMedlemskap5')}
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div> }
        {this.minstEttMedlemskap(this.state.medlemsData) &&

          <MedlemskapTable medlemData={this.state.medlemsData}
                           removeMedlem={(i) => this.removeMedlem(i)}/>}
        <br />
        <br />
        <p>
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="infobox" title="terminfestet">
                <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
                <span className="helptext">
                  {i18n.t('minJegerside:terminfestetKonkurranseskytting')}
                </span>
              </div>
            </div>
          </div>
        </p>
        <MedlemskapForm handleSubmits={this.handleSubmits}/>
      </>
    );
  }
}

export default MedlemskapApp;
