import env_vars_def from '../../env_vars';

let env_vars = env_vars_def;
const root = document.querySelector('#root');
const contextPath = root ? root.getAttribute('data-context-path') : '';
const loadEnvVars = () => fetch(`${contextPath}/assets/generated/env_vars.json`).then(async (res) => {
  env_vars = await res.json();
}).catch((err) => {
  console.error('Could not load environment variables.', err);
});

export {env_vars};
export default loadEnvVars;
