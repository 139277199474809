/* eslint-disable react/jsx-one-expression-per-line,react/jsx-no-target-blank */
import React, {Component} from 'react';
import Tablevaapen from '../vaapentable/tablevaapen';
import VaapenForm from '../forms/vaapen-form';
import '../../form/icons/app-helptext-icon.scss';
import '../../form/icons/infoboks.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import Betalingsopplysninger from '../../../components/minjegerside/betalingsopplysninger/betalingsopplysninger';
import {Personopplysninger} from '../personopplysninger/personopplysninger';
import Jegeropplysninger from '../../../components/minjegerside/jegeropplysninger/jegeropplysninger';
import Jegeravgiftkort from '../../../components/minjegerside/jegeravgiftkort/jegeravgiftkort';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import {AuthGuard} from '../../../utilities/session-management/auth-guard';
import {SessionManager, SessionType} from '../../../utilities/session-management/session-manager';
import FileSaver from 'file-saver';
import Instruktor from '../../../components/minjegerside/jegerinstruktor/instruktor';
import JegeravgiftLisensjeger from '../../../components/minjegerside/br-buttons/jegeravgift-lisensjeger';
import JegerAvgiftIkkeBetaltVarsel from '../../../components/minjegerside/jegeravgiftkort/jegerAvgiftIkkeBetaltVarsel';
import SamtykkeToggle from '../../../components/minjegerside/samtykke/samtykkeToggle';
import i18n from '../../../utilities/i18n';
import {Breadcrumbs} from '../../breadcrumbs/breadcrumbs';
import Proveskudd from '../../../components/minjegerside/proveskudd/proveskudd';
import Punktliste from '../../../components/minjegerside/punktliste/punktliste';
import {env_vars} from '../../../utilities/load-env';
import ProveskuddStevner from '../proveskudd/proveskuddStevner';
import MedlemskapApp from '../medlemskap/medlemskap/medlemskap-app';
import Hundeopplysninger from '../hundeopplysninger/hundeopplysninger';
import Avtaler from '../avtaler/avtaler';

class App extends Component {

  state = {
    vaapens: [{
      navn: '',
      kaliber: '',
      nummer: '',
    }],
    betalingsdata: [{
      jaktaar: '',
      tilleggsavgift: '',
      betalt_dato: '',
      jakttype: '',
    }],
    showVaapenRegistrering: false,
    vaapenRegistreringErrorMessage: '',
    vaapenSlettingErrorMessage: '',
    jegerAvgiftkortPDFErrorMessage: '',
    jegernr: '',
  };

  componentDidMount() {
    if (SessionManager.sessionType === SessionType.OIDC) {
      JegerwebApiService.get('/vaapen/')
        .then(res => {
          this.setState({vaapens: res.data});
        });
    }
    JegerwebApiService.get('/jeger/:sessiontype/betalingsinfo/:lang')
      .then(response => {
        this.setState({
          betalingsdata: response.data,
        });
      }).catch(err => {
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
    JegerwebApiService.get('/vaapen/:sessiontype/samtykke/')
      .then(response => {
        this.setState({
          showVaapenRegistrering: response.data.vaapeninfo,
        });
      }).catch(err => {
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
    JegerwebApiService.get('/jeger/:sessiontype/:lang')
      .then(response => {
        this.setState({
          jegernr: response.data.jegernr,
        });
      }).catch(err => {
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
  }

  downloadJegeravgiftskortData = () => {
    JegerwebApiService.get('/pdf/:sessiontype/jegeravgiftskort', {responseType: 'blob'})
      .then((response) => {
        const pdfBlob = new Blob([response.data]);
        FileSaver.saveAs(pdfBlob, 'jegeravgiftskort.pdf');
      }).catch(err => {
        this.setState({
          jegerAvgiftkortPDFErrorMessage: i18n.t ('minJegerside:jegerAvgiftkortPDFErrorMessage')});
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });

  };

  removeVaapen(index) {
    JegerwebApiService.delete(`/vaapen/${this.state.vaapens[index].lopenr}`)
      .then((response) => {
        this.setState({
          vaapens: response.data,
          vaapenSlettingErrorMessage: ''
        });
      }).catch(err => {
        this.setState({vaapenSlettingErrorMessage: i18n.t('minJegerside:vaapenSlettingErrorMessage')});
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
  }

  handleSubmit = vaapen => {
    JegerwebApiService.put('/vaapen/', vaapen)
      .then((response) => {
        this.setState({
          vaapens: response.data,
          vaapenRegistreringErrorMessage: ''
        });
      }).catch(err => {
        this.setState({vaapenRegistreringErrorMessage: i18n.t('minJegerside:vaapenRegistreringErrorMessage')});
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);

      });
  };

  handleSamtykke = (samtykkeGiven) => {
    this.setState({showVaapenRegistrering: samtykkeGiven});
  };

  minstEttVaapen(vaapens) {
    for (const vaapen of vaapens) {
      if (vaapen.navn) {
        return true;
      }
    }
    return false;
  }

  render() {
    return (
      <article title="minJegerside">
        <div className="container">
          <Breadcrumbs crumbKey="jegerside" title={i18n.t('felles:minJegerside')}/>
          <br/>
          <Punktliste />
          <br />
          <JegeravgiftLisensjeger/>
          {this.state.betalingsdata[0].jakttype
          ? <> <br/>
            <p>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8">
                  <div className="infobox" title="jegeravgiftskort">
                    <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
                    <span className="helptext">
                      {i18n.t('minJegerside:detteErDetNyeJegeravgiftskortetDitt')}
                    </span>
                  </div>
                </div>
              </div>
            </p>
            <Jegeravgiftkort vaapens={this.state.vaapens}/>
            <br />
          </>
          : <div><br/><JegerAvgiftIkkeBetaltVarsel data={this.state.betalingsdata[0]}/></div>}
          <br />
          <Personopplysninger downloadJegeravgiftskortData={this.downloadJegeravgiftskortData}
                              jegerAvgiftkortPDFErrorMessage={this.state.jegerAvgiftkortPDFErrorMessage}
                              betalingsdata={this.state.betalingsdata}
                           />
          <Jegeropplysninger/>
          <Instruktor/>
          <AuthGuard sessionType={SessionType.OIDC}>
            <Proveskudd/>
            <ProveskuddStevner/>
          </AuthGuard>
          <br />
          <Betalingsopplysninger databetaling={this.state.betalingsdata}/>
          <br />
          <Hundeopplysninger jegernr={this.state.jegernr}/>
          <br />
          <Avtaler jegernr={this.state.jegernr}/>
          <AuthGuard sessionType={SessionType.OIDC}>
            <p>
              {this.minstEttVaapen(this.state.vaapens) &&
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <div className="infobox" title="Registrerte våpen">
                    <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
                    <span className="helptext">
                      {i18n.t('minJegerside:tabellenViserVaapen')}
                    </span>
                  </div>
                </div>
              </div>}
            </p>
            {this.minstEttVaapen(this.state.vaapens) &&
            <Tablevaapen vaapenData={this.state.vaapens}
                         removeVaapen={(i) => this.removeVaapen(i)}/>}
            {this.state.showVaapenRegistrering
                      ? <>
                        {this.state.vaapenSlettingErrorMessage && <h1 className="nettverksfeil">
                          {this.state.vaapenSlettingErrorMessage}
                        </h1>}
                        <br />
                        <p>
                          <div className="row">
                            <div className="col-xs-12 col-sm-12">
                              <div className="infobox" title="opplysninger om våpen">
                                <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
                                <span className="helptext">
                                  {i18n.t('minJegerside:herLeggerDuInnOpplysningerOmVaapen')}
                                </span>
                              </div>
                            </div>
                          </div>
                        </p>
                        {this.state.vaapenRegistreringErrorMessage && <h1 className="nettverksfeil">
                          {this.state.vaapenRegistreringErrorMessage}
                        </h1>}
                        <div title="button - registrer nytt våpen">
                          <VaapenForm handleSubmit={this.handleSubmit}/>
                        </div>
                      </>
            : <SamtykkeToggle handleSamtykke={(s) => this.handleSamtykke(s)}/> }
            <br />
            <div title="button - registrer medlemskap">
              <MedlemskapApp />
            </div>
            <br />
            <br />
            <p className="normal-br-link">
              {i18n.t('minJegerside:hvordanEndreDineOpplysninger1')}
              <a className="link" href={env_vars.BRREG_FORSIDE_URL + 'person/jegerregisteret/'}>
                {i18n.t('minJegerside:hvordanEndreDineOpplysninger2')}
              </a>.
            </p>
          </AuthGuard>
        </div>
      </article>
    );
  }
}

export default App;
