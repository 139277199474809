import React, {Component} from 'react';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import moment from 'moment';
import i18n from '../../../utilities/i18n';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';


class Proveskudd extends Component {
  state = {
    proveskudd: [{
      id: '',
      antall: '',
      dato: '',
    }],
    defaultErrorMessage: '',
  };

  componentDidMount() {
    JegerwebApiService.get('/jeger/:sessiontype/:lang')
      .then(response => {
        this.setState({
          proveskudd: response.data.proveskudd,
          defaultErrorMessage: ''
        });
      }).catch(err => {
        this.setState({defaultErrorMessage: i18n.t('minJegerside:defaultErrorMessage')});
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
  }

  render() {
    return (
      (this.state.proveskudd[0] ?
        <section title="obligatoriske skudd">
          <p>
            <div className="row">
              <div className="col-xs-12 col-sm-12">
                <div className="infobox">
                  <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
                  <span className="helptext">
                    {i18n.t('minJegerside:forJegerenKanTa')}
                  </span>
                </div>
              </div>
            </div>
          </p>
          <header>
            <div className="border-bottom border-dark">
              <h2>
                {i18n.t('minJegerside:obligatoriskeskudd')}
                {' '}
              </h2>
            </div>
          </header>

          {this.state.defaultErrorMessage ?
            this.state.defaultErrorMessage && <h1 className="nettverksfeil">
              {this.state.defaultErrorMessage}
            </h1> : null}
          {
            this.state.proveskudd.map((customer) => (
              <body key={customer.id}>
                <div className="mb-5">
                  <div className="row">
                    <div className="col-12 col-md-5 state">
                      <strong>{i18n.t('minJegerside:dato')}</strong>
                    </div>
                    <div className="col-12 col-md-7 state">
                      {moment(customer.dato).format('DD.MM.YYYY')}
                    </div>
                    <div className="col-12 col-md-5 state">
                      <strong>{i18n.t('minJegerside:antallObligatoriskeskudd')}</strong>
                    </div>
                    <div className="col-12 col-md-7 state">{customer.antall}</div>
                  </div>
                </div>
              </body>
                )
            )
          }
        </section> : this.state.proveskudd)
    );
  }
}

export default Proveskudd;
