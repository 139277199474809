import './user-header.scss';
import React, {useEffect, useState} from 'react';
import {LogoutButton} from './logout-button/logout-button';
import {SessionManager} from '../../utilities/session-management/session-manager';
import {JegerwebApiService} from '../../services/api-service/jegerweb-api-service';


export const UserHeader: React.FC = () => {
  const [isReady, setReady] = useState(false);


  useEffect(() => {
    if (SessionManager.session) {
      setReady(SessionManager.session.isReady());
      SessionManager.session.addReadyChangeListener((b) => {
        setReady(b);
      });
    }
  }, []);

  const initialState = {
    jeger: {
      navn: ''
    },
  };
  const [jegerdata, setJegerdata] = useState(initialState);

  useEffect(() => {
    if (isReady) {
      JegerwebApiService.get('/jeger/:sessiontype/navn:lang')
        .then(response => {
          setJegerdata(response.data);
        }).catch(err => {
          console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
        });
    }
  }, [isReady]);


  if (isReady) {
    return (
      <header className="user-header no-print">
        <div className="user-header__inner center">
          <p className="user-header__fullname">{jegerdata.jeger.navn}</p>
          <LogoutButton/>
        </div>
      </header>
    );
  } else {
    return null;
  }
};
