import {useHistory} from 'react-router-dom';
import React, {useEffect} from 'react';
import {SessionManager, SessionType} from '../session-manager';
import {env_vars} from '../../load-env';
import {OidcSession} from './oidc-session';
import Oidc from 'oidc-client';


export const LoginCallbackPage: React.FC = () => {
    const history = useHistory();

    useEffect(() => {
        SessionManager.createSession(SessionType.OIDC).then((session) => {
            (session as OidcSession).userManager.signinRedirectCallback().then(() => {
                history.replace('/');
            }).then(() => session.start())
                .catch(e => {
                    console.error('Something went wrong during signin.', e);
                    // @ts-ignore
                    window.location.replace(env_vars.OIDC_POST_LOGOUT_REDIRECT_URI);
                });
        });
    }, []);

    return null;
};

export const OidcSilentRenewPage: React.FC = () => {
    const history = useHistory();

    useEffect(() => {
        // @ts-ignore
        new Oidc.UserManager().signinSilentCallback()
            .catch((e) => {
                console.error('Something went wrong during silent renew.', e);
                history.replace(env_vars.OIDC_ERROR_AUTHENTICATED_REDIRECT_URI);
            });
    }, [history]);

    return null;
};