import React, {Component} from 'react';
import {Button} from '../../form/button/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import './vaapen-form.scss';
import i18n from '../../../utilities/i18n';
import '../../form/button/button.scss';

class VaapenForm extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      navn: '',
      kaliber: '',
      nummer: '',
      godkjentDato: '',
      open: false,
      formErrors: {navn: '', kaliber: '', nummer: ''},
      formValid: false,
    };
    this.state = this.initialState;
  }

  handleChange = event => {
    const {name, value} = event.target;
    this.setState({
      [name]: value
    },
    () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    const validators = {
      navn: {
        validate: () => value.length >= 2 && value.length <= 28,
        errorMessage: i18n.t('minJegerside:feltetMaaInneholdeMinst2Tegn1')
      },
      kaliber: {
        validate: () => value.length >= 2 && value.length <= 28,
        errorMessage: i18n.t('minJegerside:feltetMaaInneholdeMinst2Tegn2')
      },
      nummer: {
        validate: () => value.length >= 2 && value.length <= 28,
        errorMessage: i18n.t('minJegerside:feltetMaaInneholdeMinst2Tegn3')
      }
    };
    const formErrors = {
      ...this.state.formErrors,
      [fieldName]: validators[fieldName].validate() ? '' : validators[fieldName].errorMessage
    };
    this.setState({
      formErrors,
      formValid: !Object.keys(formErrors).find(key => formErrors[key] !== ''),
    });
  }

  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  errorClass(error) {
    return (error.length === 0 ? '' : ' has-error');
  }

  onFormSubmit = (event) => {
    event.preventDefault();

    this.props.handleSubmit(this.state);
    this.setState(this.initialState);
  };

  render() {
    return (
      <React.Fragment>
        <Button id="registrer-vaapen-btn"
                text={i18n.t('minJegerside:registrerNyttVaapen')}
                isPrimary isSubmit
                aria-label="Left Align"
                onClick={() => this.toggle()}
                />
        <div className={'collapse' + (this.state.open ? 'in' : '')}>
          <form onSubmit={this.onFormSubmit}>
            <div className={`form-group ${this.errorClass(this.state.formErrors.navn)}`}>
              <label htmlFor="navn">{i18n.t('minJegerside:vaapennavn')}</label>
              <input className='vaapen-input'
                     required
                     type="text"
                     name="navn"
                     placeholder={i18n.t('minJegerside:vaapennavn') + '...'}
                     value={this.state.navn}
                     onChange={this.handleChange}
                     maxLength="28"
                />
            </div>
            <label className="error-filling">
              <strong>{this.state.formErrors.navn}</strong>
            </label>
            <div className={`form-group ${this.errorClass(this.state.formErrors.kaliber)}`}>
              <label htmlFor="kaliber">{i18n.t('minJegerside:kaliber')}</label>
              <input className='vaapen-input'
                     required
                     type="text"
                     name="kaliber"
                     placeholder={i18n.t('minJegerside:kaliber') + '...'}
                     value={this.state.kaliber}
                     onChange={this.handleChange}
                     maxLength="28"
                />
            </div>
            <label className="error-filling">
              <strong>{this.state.formErrors.kaliber}</strong>
            </label>
            <div className={`form-group ${this.errorClass(this.state.formErrors.nummer)}`}>
              <label htmlFor="nummer">{i18n.t('minJegerside:vaapennummerPipenummer')}</label>
              <input className='vaapen-input'
                     required
                     type="text"
                     name="nummer"
                     placeholder={i18n.t('minJegerside:vaapennummerPipenummer') + '...'}
                     value={this.state.nummer}
                     onChange={this.handleChange}
                     maxLength="28"
                />
            </div>
            <div>
              <label className="error-filling">
                <strong>{this.state.formErrors.nummer}</strong>
              </label>
              <div>
                <br />
                <Button id="saveBtn"
                        text={' ' + i18n.t('minJegerside:lagre')}
                        aria-label="Left Align"
                        isPrimary isSubmit
                        icon={<FontAwesomeIcon icon={faSave}/>}
                        disabled={!this.state.formValid}
                                    />
                <Button id="registrer-vaapen-btn1"
                        text={i18n.t('minJegerside:avbryt')}
                        aria-label="Left Align"
                        isPrimary
                        onClick={() => this.toggle()}
                                    />
                <br />
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default VaapenForm;
