import idleTimeout from 'idle-timeout';
import {OidcSession} from './oidc/oidc-session';
import {BasicAuthSession} from './basic-auth/basic-auth-session';
import IdleTimeout from 'idle-timeout/dist/IdleTimeout';
import {env_vars} from '../load-env';


export enum SessionType {
  OIDC = 'sessionType_oidc',
  BASIC_AUTH = 'sessionType_basic',
}

export type ReadyChangeCallback = (ready: boolean) => void;

export interface Session {
  start: (params?: any) => Promise<boolean>;
  end: () => void;
  signin: (params?: any) => Promise<void>;
  isValid: () => boolean;
  isReady: () => boolean;
  addReadyChangeListener: (readyCallback: ReadyChangeCallback) => void;
}

export class SessionManager {

  static session: Session;
  static sessionType: SessionType;
  private static idleTimer: IdleTimeout;


  static async createSession(sessionType: SessionType) {
    this.sessionType = sessionType || SessionType.OIDC;

    this.session = await {
      [SessionType.OIDC]: async () => new OidcSession(),
      [SessionType.BASIC_AUTH]: async () => new BasicAuthSession()
    }[this.sessionType]();

    this.session.addReadyChangeListener(() => {
      this.idleTimer = idleTimeout(() => {
        console.log('Session timed out');
        this.endSession();
      }, {
        element: document as unknown as HTMLElement,
        timeout: env_vars.SESSION_TIMEOUT_MS as unknown as number,
        loop: false
      });
    });

    return this.session;
  }


  static endSession() {
    this.idleTimer.destroy();
    this.session.end();
    delete this.session;
    delete this.sessionType;
  }

  static hasValidSession() {
    return !!(this.session && this.session.isValid);
  }
}

