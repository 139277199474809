import React from 'react';
import QRcode from 'qrcode.react';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import {env_vars} from '../../../utilities/load-env';
import i18n from '../../../utilities/i18n';


class JegerKontrollQRcode extends React.Component {
  state = {
    id: {
      jeger: {
        guid: '',
      },
      jegerKontrollQRcodeErrorMessage: '',
    }
  };

  componentDidMount() {
    JegerwebApiService.get('/jeger/:sessiontype/:lang')
      .then(response => {
        this.setState({
          id: response.data,
          jegerKontrollQRcodeErrorMessage: '',
        });
      }).catch(err => {
        this.setState({
          jegerKontrollQRcodeErrorMessage: i18n.t('minJegerside:jegerKontrollQRcodeErrorMessage')});
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
  }

  render() {
    return (
      <div className="nettverksfeil">
        {this.state.jegerKontrollQRcodeErrorMessage ?
              this.state.jegerKontrollQRcodeErrorMessage :
              <div className="qrcode">
                <div className="qr-field">
                  <QRcode title="QRcode" value={env_vars.BRREG_SJEKKJEGER_URL + 'sjekkJeger?id=' + this.state.id.jeger.guid}
                />
                  <p>
                    QR-koden vises frem ved kontroll.
                  </p>
                </div>
              </div>}
      </div>
    );
  }
}

export default JegerKontrollQRcode;


