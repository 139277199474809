import React, {useEffect, useState} from 'react';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import i18n from 'i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {SessionManager, SessionType} from '../../../utilities/session-management/session-manager';

function Avtaler(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect( () =>{
    if (SessionManager.sessionType !== SessionType.BASIC_AUTH) {
      const getAvtaler = async() =>{
        await JegerwebApiService.get('/jeger/deals/')
          .then(response => {
            setData(response.data);
          }).catch(err => {
            console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
          }).finally(() => {
            setIsLoading(false);
          });
      };
      if (props.jegernr !== '') {
        getAvtaler();
      }
    }
  },[props.jegernr]);

  const DisplayTableHeader = () => (
    <thead>
      <tr>
        <th className="table-cell-headline">{i18n.t('avtaler:tidsrom')}</th>
        <th className="table-cell-headline">{i18n.t('avtaler:hundefører')}</th>
        <th className="table-cell-headline">{i18n.t('avtaler:førerTlf')}</th>
        <th className="table-cell-headline">{i18n.t('avtaler:kommune')}</th>
        <th className="table-cell-headline">{i18n.t('avtaler:jaktområde')}</th>
        <th className="table-cell-headline">{i18n.t('avtaler:art')}</th>
      </tr>
    </thead>
  );

  const DisplayTableData = () => data && (
    data.map((avtale) => (
      <tr className="data" key={avtale.avtaleid}>
        <td data-heading={i18n.t('avtaler:tidsrom')} className="table-cell-data">
          {moment(avtale.fraDato).format('DD.MM.YYYY') + ' - ' +
              moment(avtale.tilDato).format('DD.MM.YYYY')}
        </td>
        <td data-heading={i18n.t('avtaler:hundefører')} className="table-cell-data">{avtale.ekvipasje.forer.navn}</td>
        <td data-heading={i18n.t('avtaler:førerTlf')} className="table-cell-data">{avtale.forerTlf}</td>
        <td data-heading={i18n.t('avtaler:kommune')} className="table-cell-data">{avtale.kommune}</td>
        <td data-heading={i18n.t('avtaler:jaktområde')} className="table-cell-data">{avtale.omrade}</td>
        <td data-heading={i18n.t('avtaler:dyr')} className="table-cell-data">{avtale.dyr}</td>
      </tr>
    ))
  );

  if (isLoading || data.length === 0 ) {
    return <></>;
  }

  return (
    <section title="avtaler">
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <div className="infobox" title="avtaleinfo">
            <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
            <span className="helptext normal-br-link">
              {i18n.t('avtaler:infoBlokk')}
              <a href="https://www.altinn.no/">
                {i18n.t('avtaler:altinn')}
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container">
          <header>
            <div className="row">
              <div className="col-12 pt-1">
                <div className="border-bottom border-dark">
                  <h2>{i18n.t('avtaler:title')}</h2>
                </div>
              </div>
            </div>
          </header>
          <table className="table-container__table table-container__table--break-sm">
            <DisplayTableHeader/>
            <tbody>
              <DisplayTableData/>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Avtaler;
