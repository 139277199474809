import React, {useMemo} from 'react';
import './breadcrumbs.scss';
import {env_vars} from '../../utilities/load-env';
import {Link} from 'react-router-dom';
import i18n from '../../utilities/i18n';

interface Crumb {
  name: string;
  path: string;
  external?: boolean;
  forceReload?: boolean;
  parent?: string;
}

export const Breadcrumbs: React.FC<{ crumbKey: string; crumbData?: object }> = (props) => {
  // eslint-disable-next-line
  const allCrumbs = {
    brreg: () => ({
      name: i18n.t('felles:forsiden'),
      path: 'https://www.brreg.no/',
      external: true
    }),
    jegerregisteret: () => ({
      name: i18n.t('felles:jegerregisteret'),
      path: 'https://www.brreg.no/jegerregisteret/',
      external: true,
      parent: 'brreg'
    }),
    jegerside: () => ({
      name: i18n.t('felles:minJeger'),
      path: 'https://minjegerside.brreg.no/nb',
      forceReload: true,
      parent: 'jegerregisteret'
    }),
    betaling: () => ({
      name: i18n.t('felles:betaling'),
      path: 'https://minjegerside.brreg.no/nb/betaling',
      parent: 'jegerside'
    }),
  };

  const crumbs = useMemo(() => {
    const crumbList: Crumb[] = [];
    let cur = props.crumbKey;
    while (cur) {

      // @ts-ignore
      const crumb = allCrumbs[cur](props.crumbData);
      crumbList.unshift(crumb);
      cur = crumb.parent;
    }
    return crumbList;
  }, [props.crumbKey, props.crumbData,allCrumbs]);


  return (
    <div className='row padding-xs-sm pt-3 pb-3 clearfix'>
      <ul className='breadcrumb__list'>
        {
          crumbs.map((crumb, idx) => {
            if (idx === crumbs.length - 1) {
              return <li key={crumb.path} className='breadcrumb__item breadcrumb__item--active'>{crumb.name}</li>;
            } else {
              const className = 'breadcrumb__item' + (idx === crumbs.length - 2 ? ' breadcrumb__item--parent' : '');
              return (
                <li key={crumb.path} className={className}>
                  {
                    crumb.external
                        ? <a href={crumb.path}>{crumb.name}</a>
                        : crumb.forceReload
                        ? <a href={env_vars.EXPRESS_CONTEXT_PATH + crumb.path}>{crumb.name}</a>
                        : <Link to={crumb.path}>{crumb.name}</Link>
                  }
                </li>
              );
            }
          })
        }
      </ul>
    </div>
  );
};
