import {ReadyChangeCallback, Session} from '../session-manager';
import {env_vars} from '../../load-env';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import {HttpStatus} from '../../../consts/http-status';
import i18n from '../../../utilities/i18n';

export interface BasicAuthUserInfo {
  hunterid: string;
  birthdate: string;
}

const BASIC_AUTH_KEY = 'basic_auth_info';

export class BasicAuthSession implements Session {
  userInfo?: BasicAuthUserInfo;
  isLoading = true;

  readyChangeSubs: ReadyChangeCallback[] = [];


  async start() {
    this.isLoading = true;
    this.getUserInfo();
    if (!this.userInfo) {
      this.readyChange();
      window.location.pathname = '/utenlandsk/login';
      return false;
    } else {
      this.readyChange();
      this.isLoading = false;
      return true;
    }
  }

  async end() {
    await JegerwebApiService.get('/jeger/utenlandsk/nb', {
      auth: {username: '', password: ''}              // Calling API with invalid auth info to invalidate session cookie
    }).catch(() => null);
    localStorage.removeItem(BASIC_AUTH_KEY);
    // @ts-ignore
    window.location.href = env_vars.OIDC_POST_LOGOUT_REDIRECT_URI;
  }

  async signin(basicAuthUserInfo: BasicAuthUserInfo) {
    try {
      await JegerwebApiService.get('/jeger/utenlandsk/nb', {
        auth: {
          username: basicAuthUserInfo.hunterid,
          password: basicAuthUserInfo.birthdate
        }
      }).then(() => {
        this.userInfo = basicAuthUserInfo;
        localStorage.setItem(BASIC_AUTH_KEY, JSON.stringify(basicAuthUserInfo));
      });
    } catch (error) {
      console.error(error);
      if (error.response.status === HttpStatus.UNAUTHORIZED) {
        throw new Error(i18n.t('basicAuthLogin:jegernrSkalBestataAv6Eller8Siffer1'));
      } else {
        throw new Error('Noe gikk galt. Prøv på nytt senere');
      }
    } finally {
      this.isLoading = false;
      this.readyChange();
    }
  }

  isValid() {
    return !!(this.userInfo);
  }

  isReady() {
    return this.isValid() && !this.isLoading;
  }

  addReadyChangeListener(readyCallback: (ready: boolean) => void) {
    this.readyChangeSubs.push(readyCallback);
  }

  readyChange() {
    this.readyChangeSubs.forEach(callback => callback(this.isReady()));
  }

  getUserInfo() {
    if (!this.userInfo) {
      const storedInfo = localStorage.getItem(BASIC_AUTH_KEY);
      if (storedInfo) {
        this.userInfo = JSON.parse(storedInfo);
      }
    }
    return this.userInfo;
  }
}
