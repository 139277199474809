import React, {Component} from 'react';
import i18n from '../../../utilities/i18n';
import {Button} from '../../form/button/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import '../../form/button/button.scss';
import './medlemskap-form.scss';
import { DATA } from '../../../consts/data';

import Select from 'react-select';


class MedlemskapForm extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      medlemsnr: '',
      medlemsorganisasjon: '',

      open: false,

      formErrors: { medlemsnr: '' },
      formValid: false,
    };
    this.state = this.initialState;
  }

  handleChange = event => {
    const {name, value} = event.target;
    this.setState({
      [name]: value
    },
    () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    const validators = {
      medlemsnr: {
        validate: () => value.length >= 1 && value.length <= 10,
        errorMessage: i18n.t('minJegerside:feltetMaaInneholdeMinst1Tegn1')
      },
    };
    const formErrors = {
      ...this.state.formErrors,
      [fieldName]: validators[fieldName].validate() ? '' : validators[fieldName].errorMessage
    };
    this.setState( {
      formErrors,
      formValid: !Object.keys(formErrors).find(key => formErrors[key] !== ''),
    });
  }

  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  errorClass(error) {
    return (error.length === 0 ? '' : ' has-error');
  }

  onFormSubmit = (event) => {
    event.preventDefault();

    this.props.handleSubmits({
      medlemsnr: this.state.medlemsnr,
      medlemsorganisasjon: this.state.medlemsorganisasjon.values,
    });

    this.setState(this.initialState);
  };

  render() {
    return (
      <React.Fragment>
        <Button id="registrer-medlemskap-btn"
                text={i18n.t('minJegerside:registrerMedlemskap')}
                isPrimary isSubmit
                aria-label="Left Align"
                onClick={() => this.toggle()}
        />
        <div className={'collapse' + (this.state.open ? 'in' : '')}>
          <form onSubmit={this.onFormSubmit}>
            <br />
            <Select placeholder={i18n.t('minJegerside:velgMedlemsorganisasjon')}
                    value={this.state.medlemsorganisasjon}
                    options={DATA}
                    onChange={data => this.setState({medlemsorganisasjon: data})}
            />
            <div className={`form-group ${this.errorClass(this.state.formErrors.medlemsnr)}`}>
              <label htmlFor="medlemsnr">{i18n.t('minJegerside:medlemsnummer')}</label>
              <input className='medlemsnummer-input'
                     required
                     type="text"
                     name="medlemsnr"
                     placeholder={i18n.t('minJegerside:medlemsnummer') + '...'}
                     value={this.state.medlemsnr}
                     onChange={this.handleChange}
                     maxLength="10"
        />
            </div>
            <label className="error-filling">
              <strong>{this.state.formErrors.medlemsnr}</strong>
            </label>
            <br />
            <Button text={' ' + i18n.t('minJegerside:lagre')}
                    aria-label="Left Align"
                    isPrimary isSubmit
                    icon={<FontAwesomeIcon icon={faSave}/>}
                    disabled={!this.state.formValid}
          />
            <Button id="registrer-medlemskap-btn1"
                    data-testid="registrer-medlemskap-btn"
                    text={i18n.t('minJegerside:avbryt')}
                    aria-label="Left Align"
                    isPrimary
                    onClick={() => this.toggle()}
          />
            <br />
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default MedlemskapForm;







