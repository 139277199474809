import React from 'react';
import {env_vars} from '../../utilities/load-env';
import i18n from '../../utilities/i18n';


export const Landingsside = () => (
  <div className='container'>
    <h1>
      {' '}
      {i18n.t('felles:VelkommenGaaVidere')}
      {' '}
    </h1>
    <br />
    <div className="text-center">
      <a href={env_vars.LANDINGSSIDE_URL}>
        <button className="btn btn-main mt-5"
              >
          {i18n.t('felles: gaaVidere')}
        </button>
      </a>
    </div>
  </div>
);
