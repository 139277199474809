import {SessionType} from '../utilities/session-management/session-manager';
import {useHistory} from 'react-router-dom';
import {useMemo} from 'react';

export const useSessionType = (): SessionType => {
  const history = useHistory();

  return useMemo(() => {
    if (history.location.pathname.startsWith('/utenlandsk/') || history.location.pathname === '/utenlandsk') {
      return SessionType.BASIC_AUTH;
    } else {
      return SessionType.OIDC;
    }
  }, [history]);
};
