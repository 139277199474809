import  React,{ Component } from 'react';
import './button.scss';


export class Button extends Component {
  render() {
    return (
      <button id={this.props.id}
              type={this.props.isSubmit ? 'submit' : 'button'}
              className={'btn ' + (this.props.isPrimary ? 'btn-main' : 'btn-simple') + ' mr-3'}
              disabled={this.props.disabled}
              onClick={this.props.onClick}
            >
        {this.props.icon}
        {this.props.children}
        {this.props.text}
      </button>
    );
  }
}



