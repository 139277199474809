import {BrowserRouter as Router, Redirect} from 'react-router-dom';
import App from './minjegerside/minjegerside.app/App';
import React, {useMemo} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import {AuthRoute} from '../utilities/session-management/auth-route';
import {LoginRoute} from '../utilities/session-management/login-route';
import {LoginCallbackPage, OidcSilentRenewPage} from '../utilities/session-management/oidc/oidc-login-callback-page';
import {Jegeravgiftbetaling} from './betaling/jegeravgiftbetaling';
import {I18nextProvider} from 'react-i18next';
import i18n from '../utilities/i18n';
import {IkkeJeger} from './ikkejeger/ikkejeger';
import {EtterBetaling} from './betaling/etterbetaling/etterbetaling';
import {InternalServerError} from './internalservererror/internalservererror';
import {Landingsside} from './landingsside/landingsside';
import ScrollToTop from './scrolltotop';



const AuthenticatedRoutes = () => {
  const routeMatch = useRouteMatch();
  const basePath = useMemo(() => {
    if (routeMatch.path === '/') {
      return '';
    } else {
      return routeMatch.path;
    }
  }, [routeMatch]);

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/landingsside" component={Landingsside}/>
        <LoginRoute exact path={`${basePath}/:lang/login`}/>
        <Route path={`${basePath}/login`}><Redirect to={`${basePath}/${i18n.languages}/login`}/></Route>
        <AuthRoute exact path="/ikkejeger" component={IkkeJeger}/>
        <AuthRoute exact path="/internalServerError" component={InternalServerError}/>
        <AuthRoute exact path={`${basePath}/:lang`} component={App}/>
        <AuthRoute exact path={`${basePath}/:lang/betaling`} component={Jegeravgiftbetaling}/>
        <AuthRoute exact path={`${basePath}/:lang/betaling/:result`} component={Jegeravgiftbetaling}/>
        <Route exact path='/'><Redirect to={`${basePath}/${i18n.languages}`}/></Route>
      </Switch>
    </Router>
  );
};

export const Root = () => (
  <I18nextProvider i18n={i18n}>
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path='/post-signin' component={LoginCallbackPage}/>
        <Route exact path='/silent-renew' component={OidcSilentRenewPage}/>
        <Route exact path='/error-page' component={OidcSilentRenewPage}/>
        <Route exact path='/betaling/:status'><EtterBetaling/></Route>
        <Route path='/utenlandsk'>
          <AuthenticatedRoutes />
        </Route>
        <AuthenticatedRoutes />
      </Switch>
    </Router>
  </I18nextProvider>
);
