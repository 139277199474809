import React, {Component} from 'react';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import i18n from '../../../utilities/i18n';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';


class ProveskuddStevner extends Component {
  state = {
    proveskuddFraStevner: [{
      id: '',
      antallSkudd: '',
      antallStevner: '',
      kilde: ''

    }],
    defaultErrorMessage: '',
  };

  componentDidMount() {
    JegerwebApiService.get('/jeger/:sessiontype/:lang')
      .then(response => {
        this.setState({
          proveskuddFraStevner: response.data.proveskuddFraStevner,
          defaultErrorMessage: ''
        });
      }).catch(err => {
        this.setState({defaultErrorMessage: i18n.t('minJegerside:defaultErrorMessage')});
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
  }

  render() {
    return (
      (this.state.proveskuddFraStevner[0] ?
        <section title="Prøveskudd fra stevner">
          <p>
            <div className="row">
              <div className="col-xs-12 col-sm-12">
                <div className="infobox">
                  <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
                  <span className="helptext">
                    {i18n.t('minJegerside:harduDeltatt')}
                  </span>
                </div>
              </div>
            </div>
          </p>
          <header>
            <div className="border-bottom border-dark">
              <h2>
                {' '}
                {i18n.t('minJegerside:proveskuddFraTerminfestet')}
                {' '}
              </h2>
            </div>
          </header>
          {this.state.defaultErrorMessage ?
              this.state.defaultErrorMessage && <h1 className="nettverksfeil">
                  {this.state.defaultErrorMessage}
              </h1> : null}
          {
              this.state.proveskuddFraStevner.map((customer) => (
                <body key={customer.id}>
                  <div className="mb-5">
                    <div className="row">
                      <div className="col-12 col-md-5 state">
                        <strong>{i18n.t('minJegerside:antallKonkurranser')}</strong>
                      </div>
                      <div className="col-12 col-md-7 state">
                        { customer.antallStevner }
                      </div>
                      <div className="col-12 col-md-5 state">
                        <strong>{i18n.t('minJegerside:antallSkudd')}</strong>
                      </div>
                      <div className="col-12 col-md-7 state">{ customer.antallSkudd }</div>
                      <div className="col-12 col-md-5 state">
                        <strong>{i18n.t('minJegerside:hentetFraResultatservice')}</strong>
                      </div>
                      <div className="col-12 col-md-7 state">{ customer.kilde }</div>
                    </div>
                    <hr />
                  </div>
                </body>
                  )
              )
          }
        </section> : this.state.proveskuddFraStevner)
    );
  }
}

export default ProveskuddStevner;
