import React, {useEffect, useState} from 'react';
import {JegerwebApiService} from '../../services/api-service/jegerweb-api-service';
import i18n from '../../utilities/i18n';
import {formaterAdresse} from '../../utilities/formateradresse';
import {useHistory} from 'react-router-dom';
import {erIkkeJeger} from '../../utilities/jegernotfound';


export const Jegerdata = () => {

  const initialState = {
    jeger: {
      navn: ''
    },
    jegernr: ''
  };

  const history = useHistory();

  const [jegerdata, setJegerdata] = useState(initialState);

  useEffect(() => {
    JegerwebApiService.get('/jeger/:sessiontype/:lang')
      .then(response => {
        setJegerdata(response.data);
      }).catch(err => {
        if (erIkkeJeger(err.message)) {
          history.push('/ikkejeger');
        } else {
          console.error('Feil GET detalj jeger : ' + err);
        }
      });
    // eslint-disable-next-line
  }, []);

  return (
    <section>
      <div className="border-bottom border-dark">
        <h2>{i18n.t('betaling:duErNaaInnloggetSom')}</h2>
      </div>
      <div className="mb-5">
        <div className="row">
          <div className="col-12 col-md-5 state">
            <strong>{i18n.t('felles:navn')}</strong>
          </div>
          <div className="col-12 col-md-7 state">
            {jegerdata.jeger.navn}
          </div>
          <div className="col-12 col-md-5 state">
            <strong>{i18n.t('felles:adresse')}</strong>
          </div>
          {jegerdata.adresse &&
            <div className="col-12 col-md-7 state">
              {formaterAdresse(jegerdata.adresse)}
            </div>}
          <div className="col-12 col-md-5 state">
            <strong>{i18n.t('felles:jegernummer')}</strong>
          </div>
          <div className="col-12 col-md-7 state">
            {jegerdata.jegernr ? jegerdata.jegernr.replace(/^0+/, '') : null}
          </div>
        </div>
      </div>
    </section>
  );
};
