const isUtenlandskAdresse = (adresse) => {
  const UTENLANDSK = ['9998', '9999'];
  return UTENLANDSK.includes(adresse.postnr);
};

export const formaterAdresse = (adresse) => {
  let resultAdresse = adresse.adresse1;
  if (adresse.adresse2) {
    resultAdresse += ', ' + adresse.adresse2;
  }
  if (adresse.adresse3) {
    resultAdresse += ', ' + adresse.adresse3;
  }
  if (isUtenlandskAdresse(adresse)) {
    resultAdresse += ', ' + adresse.landnavn;
  } else {
    resultAdresse += ', ' + adresse.postnr + ' ' + adresse.poststed;
  }

  return resultAdresse;
};
