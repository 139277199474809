import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import i18n from '../../utilities/i18n';
import './jegeravgiftbetaling.scss';

export const MildAdvarselGammeltJaktaar = (props) => (
  <div className="warning">
    <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
    <span className="helptext">
      <strong>{i18n.t('betaling:merk')}</strong>
      {i18n.t('betaling:betalingenGjelderForJaktaareet') + ' '}
      {props.jaktaar}
      {' '}
      (01.04.
      {props.jaktaarDeler[0]}
      -31.03.
      {props.jaktaarDeler[1]}
      )
    </span>
  </div>
);
