import React, {Component} from 'react';
import './jegeroppsummering.scss';
import {JEGER_BETALINGSVALG} from '../../../consts/betalingsvalg';
import {Button} from '../../form/button/button';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import i18n from '../../../utilities/i18n';
import {SessionManager} from '../../../utilities/session-management/session-manager';

export class Jegeroppsummering extends Component {
  state = {
    gaaTilBetalingErrorMessage: '',
  };

  handlePayment(jegernr, valg) {
    let produkt = valg;
    if (produkt === 'utvidelse') {
      produkt = 'storvilt';
    }
    JegerwebApiService.get(`/bestilling/:sessiontype/bestillkort/${produkt}/:lang`)
      .then((initBestilling) => {
        sessionStorage.setItem('session_type', SessionManager.sessionType);
        window.location.href = initBestilling.data.redirectUrl;
      }).catch(err => {
        this.setState({
          gaaTilBetalingErrorMessage: i18n.t('betaling:gaaTilBetalingErrorMessage')});
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
  }

  render() {
    const BETALING_KR = 'betaling:kr';

    const BETALINGSVALG = {
      [JEGER_BETALINGSVALG.storvilt]: {
        price: this.props.priser.storviltPris,
        label: i18n.t('betaling:jaktPaaAltVilt') + ' (' + this.props.person.jaktaar + ')'
      },
      [JEGER_BETALINGSVALG.smaavilt]: {
        price: this.props.priser.smaaviltPris,
        label: i18n.t('betaling:jaktPaaAltViltUnntatt') + ' (' + this.props.person.jaktaar + ')'
      },
      [JEGER_BETALINGSVALG.utvidelse]: {
        price: this.props.priser.utvidelsePris,
        label: i18n.t('betaling:utvidTilJaktPaaAltVilt') + ' (' + this.props.person.jaktaar + ')'
      }
    };

    if (this.props.valg === '') {
      return '';
    } else {
      return (
        <>
          <section id="dineValgDiv">
            <div className='section-header'>
              <h2>{i18n.t('betaling:oppsummering')}</h2>
            </div>

            <div className='section-body'>
              <div className='row'>
                <div className="col-9">
                  {BETALINGSVALG[this.props.valg].label}
                </div>
                <div className='col-3 price'>
                  {i18n.t(BETALING_KR) + ' '}
                  {BETALINGSVALG[this.props.valg].price}
                </div>
              </div>
              {this.props.priser.tilleggsavgift &&
              <div className='row' id="cart-tilleggsavgift">
                <div className="col-9">
                  {i18n.t('felles:tilleggsavgift')}
                </div>
                <div className='col-3 price'>
                  {i18n.t(BETALING_KR) + ' '}
                  {this.props.priser.tilleggsavgiftPris}
                </div>
              </div>}
              <div className='row'>
                <div className='col-12 line'>
                  <hr/>
                </div>
              </div>
              <div className='row'>
                <div className="col-9">
                  <strong>
                    {i18n.t('betaling:totalt')}
                  </strong>
                </div>
                <div className='col-3 price'>
                  <strong>
                    {i18n.t(BETALING_KR) + ' '}
                    {this.props.sum}
                  </strong>
                </div>
              </div>
            </div>
          </section>
          <br/>
          { this.state.gaaTilBetalingErrorMessage ?
            this.state.gaaTilBetalingErrorMessage && <h1 className="nettverksfeil">
                {this.state.gaaTilBetalingErrorMessage}
              </h1> : null }
          <br/>
          <Button id="betalingButton"
                  text={i18n.t('betaling:gaaTilBetaling')}
                  isPrimary
                  onClick={() => this.handlePayment(this.props.person.jegernr, this.props.valg)}
            />
        </>
      );
    }
  }
}
