/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import {SessionType} from './session-manager';
import {useSessionType} from '../../hooks/use-session-type';


export const AuthGuard: React.FC<{
  children: any;
  sessionType: SessionType; }> = (props) => {
  const sessionType = useSessionType();

  return (sessionType === props.sessionType)
    ? <>{props.children}</>
    : null;
};
