import React from 'react';
import {Breadcrumbs} from '../breadcrumbs/breadcrumbs';
import i18n from '../../utilities/i18n';
import {env_vars} from '../../utilities/load-env';

export const InternalServerError = () => (
  <div className='container'>
    <Breadcrumbs title={i18n.t('felles:minJegerside')} crumbKey='jegerside'/>
    <br/>
    <h1>{i18n.t('felles:minJegerside')}</h1>
    <p>
      {i18n.t('servererror:nettsideIkkeTilgjengelig')}
    </p>
    <p>
      {i18n.t('ikkejeger:taKontaktPre')}
      <a href={env_vars.BRREG_FORSIDE_URL + 'hvordan-kan-vi-hjelpe-deg/'}>
        {i18n.t('ikkejeger:taKontaktLink')}
      </a>
      {i18n.t('ikkejeger:taKontaktPost')}
    </p>
    <p>
      {i18n.t('ikkejeger:huskLogout')}
    </p>
  </div>
);
