import React, {useEffect, useMemo, useState} from 'react';
import {Jegerdata} from './jegerdata';
import {Jegerbetalingsdialog} from './betalingsvalg/jegerbetalingsdialog';
import './jegeravgiftbetaling.scss';
import {Link, useParams} from 'react-router-dom';
import {StrengAdvarselGammeltJaktaar} from './strengadvarselgammeltjaktaar';
import {JegerwebApiService} from '../../services/api-service/jegerweb-api-service';
import {MildAdvarselGammeltJaktaar} from './mildadvarselgammeltjaktaar';
import i18n from '../../utilities/i18n';
import {Breadcrumbs} from '../breadcrumbs/breadcrumbs';
import {isNorskJeger} from '../../utilities/typejeger';

const PAY_RESULT_CANCEL = 'cancel';
const PAY_RESULT_ERROR = 'error';

const JANUARY = 0;

const getPayFeeResult = (params) => {
  const {result} = params;
  switch (result) {
    case PAY_RESULT_CANCEL :
    case PAY_RESULT_ERROR :
      return result;
    default:
      return '';
  }
};

export const betalingsvarsel = (jegerdata) => {
  if (jegerdata.jaktaar) {
    const today = new Date();
    const isOld = today.getFullYear() === +jegerdata.jaktaar.split('/')[1];
    const month = today.getMonth();

    if (isOld) {
      if (month === JANUARY) {
        return 'mild';
      } else {
        return 'streng';
      }
    }
  }
  return '';
};

export const Jegeravgiftbetaling = () => {

  const MIN_JEGERSIDE = 'felles:minJegerside';

  const routeParams = useParams();
  const result = useMemo(() => getPayFeeResult(routeParams), [routeParams]);

  const [showBetalingsdialog, setShowBetalingsdialog] = useState(false);
  const [jegerdata, setJegerdata] = useState({});
  const [jaktaarDeler, setJaktaarDeler] = useState([]);
  const [typeAdvarsel, setTypeAdvarsel] = useState('');

  useEffect(() => {
    JegerwebApiService.get('/jeger/:sessiontype/:lang')
      .then((jegerdata1) => {
        setJegerdata(jegerdata1.data);
        setTypeAdvarsel(betalingsvarsel(jegerdata1.data));
        setJaktaarDeler(jegerdata1.data.jaktaar.split('/'));
        i18n.loadNamespaces('betaling');
      }).catch(err => {
        console.error('Feil GET detalj jeger: ' + err);
      });
  }, []);

  const showJegerbetalingsdialog = () => {
    setShowBetalingsdialog(true);
  };
  const utenlandskPath = () => isNorskJeger() ? '/' : '/utenlandsk/';

  return (
    <div id='jegeravgiftskortBody' className='container'>
      <Breadcrumbs crumbKey="betaling" title={i18n.t('betaling:betalingAvJegeravgift')}/>
      <br/>
      <h1>{i18n.t('betaling:betalingAvJegeravgift')}</h1>
      <p className="intro">
        {i18n.t('betaling:herVelger')}
      </p>
      <p className="normal-br-link">
        <a className="link" href="https://lovdata.no/lov/1981-05-29-38/§40">
          {i18n.t('betaling:lov')}
        </a>
      </p>
      <br/>
      <br/>
      {result === '' &&
        <>
          <Jegerdata/>
          {typeAdvarsel === 'mild' &&
          <MildAdvarselGammeltJaktaar jaktaar={jegerdata.jaktaar}
                                      jaktaarDeler={jaktaarDeler}/>}
          {typeAdvarsel === 'streng' &&
          <StrengAdvarselGammeltJaktaar jaktaar={jegerdata.jaktaar}
                                        confirmShowBetalingsdialog={showJegerbetalingsdialog}/>}
          {(typeAdvarsel !== 'streng' || showBetalingsdialog) &&
          <Jegerbetalingsdialog/>}
        </>}
      {result === PAY_RESULT_CANCEL &&
        <div className="m-4">
          {i18n.t('betaling:duHarAvbruttKjopet') + ' '}
          {i18n.t('betaling:gaaTil')}
          <Link to={utenlandskPath() + i18n.language}>{i18n.t(MIN_JEGERSIDE)}</Link>
        </div>}
      {result === PAY_RESULT_ERROR &&
        <div className="m-4">
          {i18n.t('betaling:detHarOppstaattEnFeilVennligst') + ' '}
          {i18n.t('betaling:gaaTil')}
          {' '}
          <Link to={utenlandskPath() + i18n.language}>{i18n.t(MIN_JEGERSIDE)}</Link>
        </div>}
    </div>
  );
};
