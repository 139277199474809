/*
 This file is purely for defining the names of the environment variables that should be available in the application.
 To set default values, use the .env file!

 The reason for this file is so that we can have auto-completion of environment variables, and also so we can
 use them in the server and devserver as well without having duplicate lists to maintain.
*/


module.exports = {

  EXPRESS_CONTEXT_PATH: undefined,
  JEGERWEB_API_URL: undefined,
  OIDC_ACR_VALUES: undefined,
  OIDC_CLIENT_ID: undefined,
  OIDC_REDIRECT_URI: undefined,
  OIDC_POST_LOGOUT_REDIRECT_URI: undefined,
  OIDC_SILENT_REDIRECT_URI: undefined,
  OIDC_AUTHORITY: undefined,
  OIDC_ERROR_AUTHENTICATED_REDIRECT_URI: undefined,
  SESSION_TIMEOUT_MS: undefined,
  BRREG_FORSIDE_URL: undefined,

  BRREG_JEGERREGISTERET_URL: undefined,
  BRREG_JEGERREGISTERET_FOREIGN_HUNTERS_URL: undefined,
  BRREG_REGISTRER_LISENS_URL_NORSK: undefined,
  BRREG_REGISTRER_LISENS_URL_UTENLANDSK_EN: undefined,
  BRREG_REGISTRER_LISENS_URL_UTENLANDSK_NB: undefined,
  BRREG_SJEKKJEGER_URL: undefined,
  ALTINN_URL: undefined,
  LANDINGSSIDE_URL: undefined,

};
