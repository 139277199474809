import {isNorskJegerByType, isUtenlandskJegerByType} from '../../../utilities/typejeger';
import i18n from '../../../utilities/i18n';
import {useHistory, useParams} from 'react-router';

export const EtterBetaling = () => {
  const params = useParams();
  const history = useHistory();

  const typeSession = sessionStorage.getItem('session_type');

  let typeInnlogging = null;
  if (isNorskJegerByType(typeSession)) {
    typeInnlogging = '/';
  }
  if (isUtenlandskJegerByType(typeSession)) {
    typeInnlogging = '/utenlandsk/';
  }

  let etterBetalingUrl = '/login/nb';
  if (typeInnlogging !== null) {
    let betalingStatus = params.status;

    if (!['success', 'cancel', 'error'].includes(betalingStatus)) {
      betalingStatus = 'error';
    }

    if (betalingStatus === 'success') {
      etterBetalingUrl = typeInnlogging + i18n.language;
    } else {
      etterBetalingUrl = typeInnlogging + i18n.language + '/betaling/' + betalingStatus;
    }
  }

  history.push(etterBetalingUrl);

  return null;
};
