import React, {useEffect, useState} from 'react';
import {Route, RouteProps} from 'react-router';
import {useParams} from 'react-router-dom';
import {SessionManager} from './session-manager';
import {useSessionType} from '../../hooks/use-session-type';


export const AuthRoute: React.FC<RouteProps> = (props) => {
  const [readyToRender, setReadyToRender] = useState(false);
  const sessionType = useSessionType();
  const {lang} = useParams<{ lang: string }>();

  useEffect(() => {
    const startSession = async () => {
      if (!SessionManager.session) {
        await SessionManager.createSession(sessionType).then(() => {
          SessionManager.session.start({lang}).then(r => setReadyToRender(r));
        });
      } else if (!SessionManager.hasValidSession()) {
        await SessionManager.session.start({lang}).then(r => setReadyToRender(r));
      } else {
        setReadyToRender(true);
      }
    };
    startSession();
  }, [lang,sessionType]);

  if (readyToRender) {
    return <Route {...props}/>;
  } else {
    return null;
  }
};
