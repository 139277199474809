import React, {Component} from 'react';

export class Radiovalg extends Component {

  render() {
    return (
      <div className={'row' + (this.props.valg === this.props.radioValue ? ' selected-row' : '')}
            >
        <div className="col-9">
          <label className="radio-bet radio-gradient"
                 htmlFor={this.props.radioId}>
            <span className="radio__input">
              <input id={this.props.radioId}
                     type="radio"
                     name="radio"
                     value={this.props.radioValue}
                     onClick={() => this.props.updateValg(this.props.radioValue)}
                        />
              <span className="radio__control"/>
            </span>
            <span className="radio__label">
              {this.props.label}
            </span>
          </label>
        </div>
        <div className='col-3 price radio-price'>
          kr
          {' '}
          {this.props.pris}
        </div>
      </div>
    );
  }
}
