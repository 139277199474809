import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import './medlemskap-form.scss';
import {MEDLEMSORGANISASJON} from '../../../consts/medlemsOrganisasjon';
import i18n from '../../../utilities/i18n';

const TableHeader = () => (
  <thead>
    <tr>
      <th className="state"><strong>{i18n.t('minJegerside:medlemsnummer')}</strong></th>
      <th className="state"><strong>{i18n.t('minJegerside:medlemsorganisajon')}</strong></th>
      <th className="state"><strong>{i18n.t('minJegerside:verifisert')}</strong></th>
      <th className="state"><strong>{i18n.t('minJegerside:slett')}</strong></th>
    </tr>
  </thead>
);

const TableBody = props => {
  const rows = props.medlemData.map((row, index) => (
    <tr key={index}>
      <td data-heading='Medlemsnr.' className="state">{row.medlemsnr}</td>
      <td data-heading='Medlemsorg.' className="state">{MEDLEMSORGANISASJON[row.medlemsorganisasjon]}</td>
      <td data-heading='Verifisert' className={`state ${row.verifisert === 'J' ? 'positive' : 'negative'}`}>
        <strong>
          {row.verifisert !== 'J' ? i18n.t('minJegerside:nei') : i18n.t('minJegerside:ja')}
        </strong>
      </td>
      <td data-heading={i18n.t('minJegerside:slett')} className="state">

        <button className="btn btn-trash"
                aria-label="Right Align"
                title="slett medlem"
                onClick={() => props.removeMedlem(index)}>
          <FontAwesomeIcon icon={faTrashAlt} size="2x"/>
        </button>
      </td>
    </tr>
  ));
  return <tbody>{rows}</tbody>;
};
const MedlemskapTable = (props) => {
  const {medlemData, removeMedlem, breakOn = 'small'} = props;
  let tableClass = 'table-container__table';
  if (breakOn === 'small') {
    tableClass += ' table-container__table--break-sm';
  }

  return (
    <section title="medlemstabell">
      <div className="row">
        <div className="container">
          <header>
            <div className="border-bottom border-dark">
              <h2>{i18n.t('minJegerside:registrerteMedlemskap')}</h2>
            </div>
          </header>
          <body>
            <table className={tableClass}>
              <TableHeader/>
              <TableBody medlemData={medlemData} removeMedlem={removeMedlem}/>
            </table>
          </body>
        </div>
      </div>
    </section>
  );
};


export default MedlemskapTable;
