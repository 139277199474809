import React, {useEffect, useState} from 'react';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import {formaterAdresse} from '../../../utilities/formateradresse';
import i18n from '../../../utilities/i18n';
import {useHistory} from 'react-router-dom';
import {erIkkeJeger} from '../../../utilities/jegernotfound';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-regular-svg-icons';



export const Personopplysninger = (props) => {
  const initialState = {
    person: {
      jeger: {
        navn: '',
        fodselsaar: '',
      },
      adresse: {
        adresse1: '',
        adresse2: '',
        adresse3: '',
        postnr: '',
        poststed: '',
      }
    },
  };
  const history = useHistory();
  const [persondata, setPersondata] = useState(initialState);

  useEffect(() => {
    JegerwebApiService.get('/jeger/:sessiontype/:lang')
      .then(response => {
        setPersondata({
          person: response.data
        });
      }).catch(err => {
        if (erIkkeJeger(err.message)) {
          history.push('/ikkejeger');
        } else if (err) {
          history.push('/internalServerError');
          console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
        }
      });
  }, [history]);

  return (
    <section title="personopplysninger">
      <div className="row">
        <div className="col-12 pt-1">
          <div className="float-right col-sm-3">
            {props.jegerAvgiftkortPDFErrorMessage ?
                            props.jegerAvgiftkortPDFErrorMessage && <h1 className="nettverksfeil">
                                {props.jegerAvgiftkortPDFErrorMessage}
                            </h1> : null}
            {props.betalingsdata[0].jakttype ?
              <div className="linkButton" title="PDF personopplysinger">
                <button className="btn btn-simple"
                        aria-label="Right Align"
                        onClick={props.downloadJegeravgiftskortData}>
                  <FontAwesomeIcon icon={faFilePdf} size="2x"/>
                  {' ' + i18n.t('minJegerside:jegeravgiftskortet')}
                </button>
              </div>
                            : null}
          </div>
          <header>
            <div className="border-bottom border-dark">
              <h2>{i18n.t('minJegerside:personopplysninger')}</h2>
            </div>
          </header>
        </div>
      </div>
      <body>
        <div className="mb-5">
          <div className="row">
            <div className="col-12 col-md-5 state">
              <strong>{i18n.t('felles:navn')}</strong>
            </div>
            <div className="col-12 col-md-7 state">
              {persondata.person.jeger.navn}
            </div>
            <div className="col-12 col-md-5 state">
              <strong>{i18n.t('minJegerside:fodselsaar')}</strong>
            </div>
            <div className="col-12 col-md-7 state">
              {persondata.person.jeger.fodselsaar}
            </div>
            <div className="col-12 col-md-5 state">
              <strong>{i18n.t('felles:adresse')}</strong>
            </div>
            {persondata.person.adresse &&
            <div className="col-12 col-md-7 state">
              {formaterAdresse(persondata.person.adresse)}
            </div>}
          </div>
        </div>
      </body>
    </section>
  );
};
