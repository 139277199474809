import React, {Component} from 'react';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-regular-svg-icons';
import FileSaver from 'file-saver';
import i18n from '../../../utilities/i18n';


class Jegeropplysninger extends Component {
  state = {
    jegeropplysninger: {
      jegernr: '',
      jaktaar: '',
      regdato: '',
      lisensArter: '',
      jegerprovedato: '',
      lisensJaktaar: ''
    },
    defaultErrorMessage: '',
    jegerlisensPDFErrorMessage: '',
  };

  componentDidMount() {
    JegerwebApiService.get('/jeger/:sessiontype/:lang')
      .then(response => {
        this.setState({
          jegeropplysninger: response.data,
          defaultErrorMessage: ''
        });
      }).catch(err => {
        this.setState({
          defaultErrorMessage: i18n.t('minJegerside:defaultErrorMessage')
        });
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
  }

  downloadLisensPDF = () => {
    JegerwebApiService.get('/pdf/:sessiontype/lisens', {responseType: 'blob'})
      .then((response) => {
        const pdfBlob = new Blob([response.data]);
        FileSaver.saveAs(pdfBlob, 'lisens.pdf');
      }).catch(err => {
        this.setState({
          jegerlisensPDFErrorMessage: i18n.t('minJegerside:jegerlisensPDFErrorMessage')
        });
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
  };

  render() {
    return (
      <section title="jegeropplysninger">
        <div className="row">
          <div className="col-12 pt-1">
            <div className="float-right col-sm-3">
              {this.state.jegerlisensPDFErrorMessage ?
                                this.state.jegerlisensPDFErrorMessage && <h1 className="nettverksfeil">
                                    {this.state.jegerlisensPDFErrorMessage}
                                </h1> : null}
              {this.state.jegeropplysninger.lisensArter && <div className="linkButton">
                <div title="PDF jegeropplysninger">
                  <button className="btn btn-simple"
                          aria-label="Right Align"
                          onClick={this.downloadLisensPDF}>
                    <FontAwesomeIcon icon={faFilePdf} size="2x"/>
                    {' ' + i18n.t('minJegerside:personligLisens')}
                  </button>
                </div>
              </div>}
            </div>
            <header>
              <div className="border-bottom border-dark">
                <h2>{i18n.t('minJegerside:jegeropplysninger')}</h2>
              </div>
            </header>
          </div>
        </div>
        {this.state.defaultErrorMessage ?
          this.state.defaultErrorMessage && <h1 className="nettverksfeil">
            {this.state.defaultErrorMessage}
          </h1> :
          <body>
            <div className="mb-5">
              <div className="row">
                <div className="col-12 col-md-5 state">
                  <strong>{i18n.t('felles:jegernummer')}</strong>
                </div>
                <div className="col-12 col-md-7 state">
                  {this.state.jegeropplysninger.jegernr}
                </div>
                <div className="col-12 col-md-5 state">
                  <strong>{i18n.t('minJegerside:registrert')}</strong>
                </div>
                <div className="col-12 col-md-7 state">
                  {this.state.jegeropplysninger.regdato}
                </div>
                {this.state.jegeropplysninger.jegerprovedato &&
                <div className="col-12 col-md-5 state">
                  <strong>{i18n.t('minJegerside:jegerprove')}</strong>
                </div>}
                {this.state.jegeropplysninger.jegerprovedato &&
                <div className="col-12 col-md-7 state">
                  {this.state.jegeropplysninger.jegerprovedato}
                </div>}
                {this.state.jegeropplysninger.lisensArter && <div className="col-12 col-md-5 state">
                  <strong>{i18n.t('minJegerside:lisensjeger')}</strong>
                  {' '}
                  {this.state.jegeropplysninger.lisensJaktaar}
                </div>}
                <div className="col-12 col-md-7 state">
                  {this.state.jegeropplysninger.lisensArter}
                </div>
              </div>
            </div>
          </body>}
      </section>
    );
  }
}

export default Jegeropplysninger;
