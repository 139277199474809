import React, {Component} from 'react';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-regular-svg-icons';
import FileSaver from 'file-saver';
import i18n from '../../../utilities/i18n';
import {HttpStatus} from '../../../consts/http-status';



class Instruktor extends Component {
  state = {
    instructor: [{
      autorisertDato: '',
      kursAar: '',
      gyldigTilDato: '',
      epost: '',
      mobilnr: ''
    }],
    instructorPDFErrorMessage: '',
  };

  componentDidMount() {
    JegerwebApiService.get('/jeger/:sessiontype/instruktor')
      .then(response => {
        this.setState({
          instructor: response.data
        });
      }).catch( error => {
        const statusCode = error.response ? error.response.status : null;
        return statusCode === HttpStatus.NOT_FOUND;
      });
  }

  downloadInstruktorPDF = () => {
    JegerwebApiService.get('/pdf/:sessiontype/instruktor', {responseType: 'blob'})
      .then((response) => {
        const pdfBlob = new Blob([response.data]);
        FileSaver.saveAs(pdfBlob, 'instruktor.pdf');

      }).catch(err => {
        this.setState({
          instructorPDFErrorMessage: i18n.t('minJegerside:instructorPDFErrorMessage')
        });
        console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
      });
  };

  render() {
    return (
      (this.state.instructor.autorisertDato ?
        <section title="jegerprøveinstruktør">
          <div className="row">
            <div className="col-12 pt-1">
              <div className="float-right col-sm-3">
                {this.state.instructorPDFErrorMessage ?
                    this.state.instructorPDFErrorMessage && <h1 className="nettverksfeil">
                      {this.state.instructorPDFErrorMessage}
                    </h1> : null}
                {this.state.instructor && <div className="linkButton">
                  <div placeholder="PDF jegerprøveinstruktør">
                    <button className="btn btn-simple"
                            aria-label="Right Align"
                            onClick={this.downloadInstruktorPDF}>
                      <FontAwesomeIcon icon={faFilePdf} size="2x"/>
                      {' ' + i18n.t('minJegerside:autorisasjon')}
                    </button>
                  </div>
                </div>}
              </div>
              <header>
                <div className="border-bottom border-dark">
                  <h2>{i18n.t('minJegerside:jegerproveinstruktor')}</h2>
                </div>
              </header>
            </div>
          </div>
          <body>
            <div className="mb-5">
              <div className="row">
                <div className="col-12 col-md-5 state">
                  <strong>{i18n.t('minJegerside:autorisasjonsdato')}</strong>
                </div>
                <div className="col-12 col-md-7 state">
                  {this.state.instructor.autorisertDato}
                </div>
                <div className="col-12 col-md-5 state">
                  <strong>{i18n.t('minJegerside:fornyet')}</strong>
                </div>
                <div className="col-12 col-md-7 state">
                  {this.state.instructor.kursAar}
                </div>
                <div className="col-12 col-md-5 state">
                  <strong>{i18n.t('minJegerside:gyldigTil')}</strong>
                </div>
                <div className="col-12 col-md-7 state">
                  {this.state.instructor.gyldigTilDato}
                </div>
                <div className="col-12 col-md-5 state">
                  <strong>{i18n.t('minJegerside:epost')}</strong>
                </div>
                <div className="col-12 col-md-7 state">
                  {this.state.instructor.epost}
                </div>
                <div className="col-12 col-md-5 state">
                  <strong>{i18n.t('minJegerside:mobilnummer')}</strong>
                </div>
                <div className="col-12 col-md-7 state">
                  {this.state.instructor.mobilnr}
                </div>
              </div>
            </div>
          </body>
        </section> : null
      )
    );
  }
}

export default Instruktor;
