import React, {Component} from 'react';
import i18n from '../../../utilities/i18n';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


class JegerAvgiftIkkeBetaltVarsel extends Component {
  render() {
    return (
      <div className='row'>
        <div className='col-xs-12 col-sm-12 col-md-10 col-lg-8'>
          <div className="infobox">
            <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
            <span className="helptext">
              {i18n.t('minJegerside:ikkeBetaltJegeravgift_1')}
              {this.props.data.jaktaar}
              {i18n.t('minJegerside:ikkeBetaltJegeravgifter_2')}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default JegerAvgiftIkkeBetaltVarsel;


