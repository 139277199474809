import React, {useMemo} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import './jegeravgift-lisensjeger.scss';
import {env_vars} from '../../../utilities/load-env';
import i18n from '../../../utilities/i18n';
import {isNorskJeger} from '../../../utilities/typejeger';

const JegeravgiftLisensjeger = () => {
  const routeMatch = useRouteMatch();

  const betalingPath = useMemo(() => {
    let path = routeMatch.url;
    if (!path.endsWith('/')) {
      path += '/';
    }
    path += 'betaling';
    return path;
  }, [routeMatch]);

  const findLisensjegerUrl = () => {
    if (isNorskJeger()) {
      return env_vars.BRREG_REGISTRER_LISENS_URL_NORSK;
    } else {
      return i18n.language === 'en'
        ? env_vars.BRREG_REGISTRER_LISENS_URL_UTENLANDSK_EN
        : env_vars.BRREG_REGISTRER_LISENS_URL_UTENLANDSK_NB;
    }
  };

  return (
    <div title="button">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4" title="button - jegeravgift">
          <Link to={betalingPath}
                className="row br-service-button br-button">
            <div className="col-xs-10 br-service-button-content">
              <div className="row">
                <div className="col-xs-10 remove-padding">
                  <p className="br-small-button-title">{i18n.t('minJegerside:jegeravgift')}</p>
                  <p className="br-button-description">{i18n.t('minJegerside:betalAvgift')}</p>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4" title="button - lisensjeger">
          <a href={findLisensjegerUrl()}
             className="row br-service-button br-button">
            <div className="col-xs-10 br-service-button-content">
              <div className="row">
                <div className="col-xs-10 remove-padding">
                  <p className="br-small-button-title ">{i18n.t('minJegerside:lisensjeger')}</p>
                  <p className="br-button-description">{i18n.t('minJegerside:registreringOgPersonligLisens')}</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default JegeravgiftLisensjeger;
