import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import {env_vars} from './load-env';

const SUPPORTED_LANGS = document.body.getAttribute('data-supported-lang')?.split(',');
const LANG_EVENT_KEY = 'brreg:lang-change';

export const initI18N = () => i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: document.documentElement.getAttribute('lang'),
    supportedLngs: SUPPORTED_LANGS,
    fallbackLng: 'nb',
    detection: {
      order: ['htmlTag', 'path', 'cookie'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: `${env_vars.EXPRESS_CONTEXT_PATH}/assets/locales/{{lng}}/{{ns}}.json`,
    },
    debug: false,
    ns: ['felles', 'betaling', 'basicAuthLogin', 'minJegerside', 'ikkejeger', 'servererror', 'hunder', 'avtaler'],
    defaultNS: 'betaling',
    keySeparator: false,
    interpolation: {
      escapeValue: false, // Not needed for react!!
      formatSeparator: ','
    },
    react: {
      wait: true,
      useSuspense: false
    },
  }, () => {
    window.addEventListener(LANG_EVENT_KEY, (ev) => {
      const oldPath = window.location.pathname.split('/');

      const langInOldPath = oldPath.find(p => ['nb', 'nn', 'en'].includes(p));
      if (langInOldPath) {
        const newPath = oldPath.map((p => p === langInOldPath ? ev.language : p));
        window.location.href = newPath.join('/');
      } else {
        window.location.href = [ev.language, ...oldPath].join('/');
      }
    });
  });

export default i18n;

