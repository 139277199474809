import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import './jegeravgiftbetaling.scss';
import i18n from '../../utilities/i18n';


export const StrengAdvarselGammeltJaktaar = (props) => (
  <div className="warning">
      <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
      <span className="helptext">
      <h2>
        {i18n.t('betaling:jaktaaretErSnartslutt')}
      </h2>
      <p>
        {i18n.t('betaling:strengAdvarselGammeltJaktaarSnartSlutt')}
          {props.jaktaar}
          {i18n.t('betaling:snart')}
      </p>
      <p>
        {i18n.t('betaling:strengAdvarselGammeltJaktaarSkalDuJakte')}
          {i18n.t('betaling:strengAdvarselGammeltJaktaarSkalDuJakteLinkText')}
       </p>
          <button className="br-button-2 br-button-2-main" onClick={props.confirmShowBetalingsdialog}>
          {i18n.t('betaling:gaaTilBetaling')}
        </button>
    </span>
  </div>
);
