import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import '../../form/icons/infoboks.scss';
import i18n from '../../../utilities/i18n';
import {isNorskJeger} from '../../../utilities/typejeger';
import {env_vars} from '../../../utilities/load-env';
import '../vaapentable/tablevaapen_table.scss';



const TableHeader = () => (
  <thead>
    <tr>
      <th className="state"><strong>{i18n.t('minJegerside:jaktaar')}</strong></th>
      <th className="state"><strong>{i18n.t('minJegerside:betaltDato')}</strong></th>
      <th className="state"><strong>{i18n.t('minJegerside:jakttype')}</strong></th>
      {isNorskJeger() &&
      <th className="state"><strong>{i18n.t('minJegerside:tilleggsavgift')}</strong></th>}
    </tr>
  </thead>
);

const TableBody = props => {
  const rows = props.databetaling.map((row, index) => (
    <tr key={index}>
      <td data-heading={i18n.t('minJegerside:jaktaar')}
          className={`state ${row.betalt_dato === i18n.t('minJegerside:ikkeBetalt')  ? 'negative' : 'positive'}`}>
        <strong>{row.jaktaar}</strong>
      </td>
      <td data-heading={i18n.t('minJegerside:betaltDato')}
          className={`state ${row.betalt_dato === i18n.t('minJegerside:ikkeBetalt') ? 'negative' : 'positive'}`}>
        <strong>{row.betalt_dato}</strong>
      </td>
      <td data-heading={i18n.t('minJegerside:jakttype')} className="state">{row.jakttype}</td>
      {isNorskJeger() &&
        <td data-heading={i18n.t('minJegerside:tilleggsavgift')} className="state">{row.tilleggsavgift}</td>}
    </tr>
  ));
  return <tbody>{rows}</tbody>;
};

const Betalingsopplysninger = (props) => {
  const {databetaling, breakOn = 'small'} = props;
  let tableClass = 'table-container__table';
  if (breakOn === 'small') {
    tableClass += ' table-container__table--break-sm';
  }

  return (
    <section title="betalingsopplysninger">
      {isNorskJeger() &&
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <div className="infobox" title="fangstrapportering">
              <FontAwesomeIcon className="helptext-icon" icon={faInfoCircle}/>
              <span className="helptext normal-br-link">
                {i18n.t('minJegerside:harDuBetaltJegeravgiftMaaDuRapportere1')}
                <a className='link' href={env_vars.ALTINN_URL + 'skjemaoversikt/statistisk-sentralbyra/jaktundersokelsen/'}>
                  {i18n.t('minJegerside:fangstrapportering')}
                </a>
                {i18n.t('minJegerside:harDuBetaltJegeravgiftMaaDuRapportere2')}
              </span>
            </div>
          </div>
        </div>}
      <div className="row">
        <div className="container">
          <header>
            <div className="row">
              <div className="col-12 pt-1">
                <div className="border-bottom border-dark">
                  <h2>{i18n.t('minJegerside:betalingsopplysninger')}</h2>
                </div>
              </div>
            </div>
          </header>
          <body>
            <table className={tableClass}>
              <TableHeader/>
              <TableBody databetaling={databetaling} />
            </table>
          </body>
        </div>
      </div>
    </section>
  );
};

export default Betalingsopplysninger;
