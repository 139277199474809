export const DATA = [
  {
    values: 943942102,
    label: ' DET FRIVILLIGE SKYTTERVESEN - DFS'
  },
  {
    values: 956792150,
    label: 'NORGES JEGER- OG FISKERFORBUND - NJFF'
  },
];
