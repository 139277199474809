import React, {useEffect, useState} from 'react';
import resources from './resources/jegerregisteret_logo.png';
import './jegeravgiftkort.scss';
import {JegerwebApiService} from '../../../services/api-service/jegerweb-api-service';
import Tablevaapen from '../vaapentable/tablevaapen';
import {AuthGuard} from '../../../utilities/session-management/auth-guard';
import {SessionType} from '../../../utilities/session-management/session-manager';
import JegerKontrollQRcode from './jegerKontrollQRcode';
import i18n from '../../../utilities/i18n';

const Jegeravgiftkort = (props) => {

  const [jegerWebData,setJegerWebData] = useState({});
  const [defaultErrorMessage, setDefaultErrorMessage] = useState('');
  const [status, setStatus] = useState([]);
  const [loading,setLoading] = useState(true);

  useEffect( () =>{
    const getJegerweb = async() =>{
      await JegerwebApiService.get('/jeger/:sessiontype/:lang')
        .then(response => {
          setJegerWebData(response.data);
          setDefaultErrorMessage('');
        }).catch(err => {
          setDefaultErrorMessage(i18n.t('minJegerside:defaultErrorMessage'));
          console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
        }).finally( () =>{
          setLoading(false);
        });
    };
    getJegerweb();

    const getStatus = async() =>{
      await JegerwebApiService.get('/jeger/:sessiontype/status/')
        .then(response => {
          setStatus(response.data);
        }).catch(err => {
          console.error('Nettverksfeil: Vi har problemer med å koble til. Prøv igjen senere', err);
        });
    };
    getStatus();
  },[]);

  const minstEttGodkjentVaapen = (vaapens) => {
    for (const vaapen of vaapens) {
      if (vaapen.godkjentDato) {
        return true;
      }
    }
    return false;
  };
  if (loading) {
    return <></>;
  }

  return (
    <section title="jegeravgiftskort">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8">
          <div className="betalt-wrapper notranslate">
            {defaultErrorMessage && <h1 className="nettverksfeil">
                {defaultErrorMessage}
              </h1>}
            <header>
              <div className="betalt-titlebar">
                <h2>Jegeravgiftskort</h2>
              </div>
            </header>
            <body>
              <br/>
              <div className="state-logo">
                <figure>
                  <img className="jegerbilde" src={resources} alt="jegerregisteret_logo"/>
                </figure>
              </div>
              <div className="kort-content">
                <div>
                  <table className="jegeravgiftskort-table">
                    <tbody>
                      <tr>
                        <td className="state">Navn:</td>
                        <td>
                          <strong>
                            {jegerWebData.jeger.navn}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="state">Fødselsår:</td>
                        <td>
                          <strong>
                            {jegerWebData.jeger.fodselsaar}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="state">Jegernummer:</td>
                        <td>
                          <strong>
                            {jegerWebData.jegernr}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong className="betalt-tekst state">Betalt:</strong>
                        </td>
                        <td>
                          <strong className="betalt-tekst">
                            {jegerWebData.jaktaar}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="state">Jakttype:</td>
                        <td>
                          <strong>
                            {jegerWebData.typejakt}
                          </strong>
                        </td>
                      </tr>
                      {status.length > 0 &&
                      <tr>
                        <td className="state">
                          Ettersøkshund:
                        </td>
                        <td>
                          <strong>{status}</strong>
                        </td>
                      </tr>}
                      {jegerWebData.lisensArter &&
                        <tr>
                          <td className="state">
                            Lisens
                            {' '}
                            {jegerWebData.lisensJaktaar}
                            :
                          </td>
                          <td>
                            <strong>
                              {jegerWebData.lisensArter}
                            </strong>
                          </td>
                        </tr>}
                    </tbody>
                  </table>
                </div>
                <JegerKontrollQRcode/>
                <AuthGuard sessionType={SessionType.OIDC}>
                  {minstEttGodkjentVaapen(props.vaapens) && (
                    <div>
                      <h3><strong>Skyteprøve for storviltjegere er avlagt med:</strong></h3>
                      <Tablevaapen vaapenData={props.vaapens.filter(vaapen => vaapen.godkjentDato)}
                                   hideDeleteColumns/>
                    </div>
                  )}
                </AuthGuard>
                {jegerWebData.infotekst &&
                  <div>
                    {jegerWebData.infotekst}
                  </div>}
              </div>
            </body>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Jegeravgiftkort;
